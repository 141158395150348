import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
  AfterViewChecked,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountSettingsService } from 'src/app/modules/shared/services/account-settings.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { LocationViewModel } from 'src/app/modules/shared/viewModels/locationViewModel';
import { GeoLocationService } from 'src/app/modules/shared/services/geo-location.service';
import { Account } from 'src/app/modules/shared/models/account';

import { WeatherDataService } from 'src/app/modules/shared/services/weather-data.service';
import { MapLayerId } from 'src/app/modules/shared/enums/weather/mapLayer';
import { DashboardEventEmitters } from 'src/app/modules/shared/events/dashboard.events';
import { TemperatureOptions } from 'src/app/modules/shared/enums/temperatureOptions';
import { WeatherWidgetTemperatureTypes } from 'src/app/modules/shared/enums/weatherTemperatureTypes';
import { EmployeeSettingsService } from 'src/app/modules/shared/services/employee-settings.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { EmployeeSettingTypes } from 'src/app/modules/settings/enums/employeeSettingTypes';
import { DataBlock } from 'src/app/modules/shared/enums/weather/darkSkyApi/DataBlock';
import { Constants } from 'src/app/modules/shared/models/constants';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalUtilityService } from 'src/app/modules/shared/services/utilities/modals-utilities.service';
import { DashboardWeatherLocationViewModel } from 'src/app/modules/settings/viewModels/dashboardWeatherLocationViewModel';

@Component({
  selector: 'app-dashboard-weather-ow',
  templateUrl: './dashboard-weather-ow.component.html',
  styleUrls: ['./dashboard-weather-ow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWeatherOWComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  //@ViewChild('dropdownWeatherlocations') dropdownWeatherlocations: ElementRef<HTMLElement>;
  @ViewChild('dropdownButtonDarkSky') dropdownButtonDarkSky: ElementRef<HTMLElement>;
  @ViewChild('forecastContainer') forecastContainer: ElementRef<HTMLElement>;
  @ViewChild('map') mapElement: ElementRef<HTMLElement>;
  @ViewChildren('stripesSpans') stripes: QueryList<ElementRef<HTMLElement>>;

  private bsModalRef: BsModalRef;
  private readonly mobileWidth = Constants.xs;
  private widgetName = 'DashboardWeatherOWComponent';

  subscriptions = new Subscription();
  weatherLocations: LocationViewModel[] = [];
  weatherLocationSelected: LocationViewModel = null;
  account: Account;
  loading = false;
  loadingMessage = `${this.translateService.instant(T.common.loading)}...`;
  employee: Employee;
  darkSkyWeatherResponse: any = null;
  mapView = false;
  map: google.maps.Map = null;

  triggerDataRequestOnSwitchView = false;

  mapForecastRequestDate: Date = null;
  mapWeatherHoursShift = 3;
  temperatureOption = TemperatureOptions.Farenheit;
  weatherWidgetTemperatureTypes = WeatherWidgetTemperatureTypes;
  public readonly T = T;

  constructor(
    private accountSettingsService: AccountSettingsService,
    private authService: AuthenticationService,
    private changeDetector: ChangeDetectorRef,
    private geoLocationService: GeoLocationService,
    private weatherDataService: WeatherDataService,
    private dashboardEventEmitters: DashboardEventEmitters,
    private readonly employeeSettingsService: EmployeeSettingsService,
    private readonly translateService: TranslateService,
    private readonly modalUtilityService: ModalUtilityService
  ) {}

  onResized(ev: Event) {
    this.setSizes();
  }

  ngOnInit() {
    this.account = this.authService.getCurrentAccount();
    this.employee = this.authService.getCurrentEmployee();
    this.getWeatherLocations();
    this.getTemperatureOption();
    this.initSubscriptions();
  }

  ngAfterViewInit() {
    if (!this.mapView && this.stripes) {
      this.stripes.toArray().forEach(({ nativeElement }) => this.style(nativeElement));
    }
  }

  ngAfterViewChecked() {
    if (!this.mapView && this.stripes) {
      this.stripes.toArray().forEach(({ nativeElement }) => this.style(nativeElement));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  isMobile(): boolean {
    return window.innerWidth <= this.mobileWidth;
  }

  switchView() {
    this.mapView = !this.mapView;
    this.changeDetector.detectChanges();

    if (this.mapView) {
      this.initMap();
    } else {
      if (this.triggerDataRequestOnSwitchView) {
        this.triggerDataRequestOnSwitchView = false;
        this.getWeatherForecastData();
      }
    }
  }

  onWeatherLocationSelected(location: LocationViewModel) {
    if (this.weatherLocationSelected !== location) {
      this.weatherLocationSelected = location;

      if (!this.mapView) {
        this.getWeatherForecastData();
      } else {
        const lat = location.latitude;
        const lng = location.longitude;
        this.map.panTo(new google.maps.LatLng(lat, lng));
        this.triggerDataRequestOnSwitchView = true;
      }

      this.changeDetector.detectChanges();
    }
  }

  private initSubscriptions() {
    this.subscriptions.add(
      this.dashboardEventEmitters.locationSettingsChanged$.subscribe(() => {
        this.getWeatherLocations();
      })
    );

    this.subscriptions.add(
      this.dashboardEventEmitters.temperatureSettingChanged$.subscribe((res) => {
        this.temperatureOption = +res.value;
        this.changeDetector.markForCheck();
      })
    );
  }

  private getWeatherForecastData() {
    const cancelFlag = !this.weatherLocationSelected ||
      !this.weatherLocationSelected.latitude ||
      !this.weatherLocationSelected.longitude;

    if(cancelFlag) {
      return;
    }

    this.loading = true;
    this.changeDetector.detectChanges();

    const lat = this.weatherLocationSelected.latitude;
    const lng = this.weatherLocationSelected.longitude;

    const handleWeatherResponse = (response: any | null) => {
      this.darkSkyWeatherResponse = response;
      this.loading = false;
      this.changeDetector.detectChanges();

      if (response) {
        this.setSizes();
      }
    }

    this.subscriptions.add(
      this.weatherDataService.getWeatherDataFromOpenWeatherApi(lat, lng, [DataBlock.MINUTELY]).subscribe({
        next: (response) => {
          handleWeatherResponse(response);
        },
        error: (_error) => {
          handleWeatherResponse(null);
        }
      })
    );
  }

  getWeatherIcon(icon: string) {
    return this.weatherDataService.getIconURL(icon, 2);
  }

  configureWidget() {
    this.bsModalRef = this.modalUtilityService.openDashboardWidgetConfigModal(this.widgetName, null);
  }

  get currentWeatherIcon() {
    return this.weatherDataService.getIconURL(this.darkSkyWeatherResponse.current.weather[0].icon, 4);
  }

  get currentWeatherStatus(): string {
    let formattedDescription = '';
    const description: string = this.darkSkyWeatherResponse.current.weather[0].description;
    if (description && description.length > 1) {
      formattedDescription = description.charAt(0).toUpperCase() + description.slice(1);
    }

    return formattedDescription;
  }

  get curentTemperatureFormatted(): string {
    const temp = this.darkSkyWeatherResponse.current.temp;
    return this.formatTemperature(temp);
  }

  get todayMinTemperatureFormatted(): string {
    const temp = this.darkSkyWeatherResponse.daily[0].temp.min;
    return this.formatTemperature(temp);
  }

  get todayMaxTemperatureFormatted(): string {
    const temp = this.darkSkyWeatherResponse.daily[0].temp.max;
    return this.formatTemperature(temp);
  }

  get currentWindSpeed() {
    return this.darkSkyWeatherResponse.current.wind_speed;
  }

  get precipProbability(): number {
    const probability = this.darkSkyWeatherResponse.daily[0].pop * 100;
    return Math.round(probability);
  }

  get todayPrecipVolume(): number {
    if (this.darkSkyWeatherResponse.daily[0].snow) {
      return this.darkSkyWeatherResponse.daily[0].snow;
    } else if (this.darkSkyWeatherResponse.daily[0].rain) {
      return this.darkSkyWeatherResponse.daily[0].rain;
    } else return 0.0;
  }

  get precipationType(): string {
    let precipationType = this.translateService.instant(T.weather.rain);
    if (this.darkSkyWeatherResponse.daily[0].snow) {
      precipationType = this.translateService.instant(T.weather.snow);
    }

    return precipationType;
  }

  get dailySummery(): string {
    return '';
  }

  get todaySunriseTime(): string {
    const time = this.darkSkyWeatherResponse.daily[0].sunrise;
    const date = new Date(time * 1000);
    const hour = date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours();
    const minutes = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes();

    const formattedTime = hour + ':' + minutes;

    return formattedTime;
  }

  get todaySunsetTime(): string {
    const time = this.darkSkyWeatherResponse.daily[0].sunset;
    const date = new Date(time * 1000);
    const hour = date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours();
    const minutes = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes();

    const formattedTime = hour + ':' + minutes;

    return formattedTime;
  }

  get stripesData(): any[] {
    const weatherSummeryArr: string[] = [];

    if (this.darkSkyWeatherResponse !== null) {
      this.darkSkyWeatherResponse.hourly.forEach((elem: any, index: number) => {
        const dailyHours = 24;
        if (index < dailyHours) {
          const dailyWeatherSummery = elem.weather[0].description;
          weatherSummeryArr.push(dailyWeatherSummery);
        }
      });
    }

    const procentDisplayData: any[] = [];
    let nextSummeryCount = 1;
    for (let i = 0; i < weatherSummeryArr.length; i++) {
      const currentSummery = weatherSummeryArr[i];
      for (let j = i + 1; j < weatherSummeryArr.length; j++) {
        const nextSummery = weatherSummeryArr[j];
        if (currentSummery === nextSummery) {
          nextSummeryCount++;
          if (j === weatherSummeryArr.length - 1) {
            const procent = (nextSummeryCount / weatherSummeryArr.length) * 100;
            procentDisplayData.push({
              summery: currentSummery,
              procent: procent,
              cssClass: this.getClassNameForWeatherSummery(currentSummery),
            });
            i = j;
          }
        } else {
          const procent = (nextSummeryCount / weatherSummeryArr.length) * 100;
          procentDisplayData.push({
            summery: currentSummery,
            procent: procent,
            cssClass: this.getClassNameForWeatherSummery(currentSummery),
          });

          if (j === weatherSummeryArr.length - 1) {
            const procent = (1 / weatherSummeryArr.length) * 100;
            procentDisplayData.push({
              summery: nextSummery,
              procent: procent,
              cssClass: this.getClassNameForWeatherSummery(nextSummery),
            });
          }

          nextSummeryCount = 1;
          i = j - 1;
          break;
        }
      }
    }
    return procentDisplayData;
  }

  get hourlyData(): any[] {
    const hourlyData: any[] = [];

    if (this.darkSkyWeatherResponse !== null) {
      this.darkSkyWeatherResponse.hourly.forEach((elem: any, index: number) => {
        const dailyHours = 24;
        if (index < dailyHours) {
          const nextData = {
            formatedTime: ((time: number) => {
              const date = new Date(time * 1000);
              const hour = date.getUTCHours();

              const formatedTime = hour < 12 ? hour + 'am' : hour - 12 === 0 ? '12pm' : hour - 12 + 'pm';
              return formatedTime;
            })(elem.dt),
            temperature: this.formatTemperature(elem.temp),
          };

          if (index === 0) {
            nextData.formatedTime = this.translateService.instant(T.calendar.now);
          }

          hourlyData.push(nextData);
        }
      });
    }

    return hourlyData;
  }

  get dailyData(): any[] {
    const dailyData: any[] = [];
    if (this.darkSkyWeatherResponse !== null) {
      this.darkSkyWeatherResponse.daily.forEach((elem: any, index: number) => {
        const maxDaysForward = 4;
        if (index > 0 && index <= maxDaysForward) {
          const nextData = {
            minTemperature: this.formatTemperature(elem.temp.min),
            maxTemperature: this.formatTemperature(elem.temp.max),
            icon: elem.weather[0].icon,
            dayName: this.getWeekDayName(elem.dt * 1000),
          };
          dailyData.push(nextData);
        }
      });
    }

    return dailyData;
  }

  private formatTemperature(temp: number): string {
    let defaultPrefix = 'C';
    if (this.temperatureOption === TemperatureOptions.Farenheit) {
      defaultPrefix = 'F';
      temp = this.celsiusToFarenheit(temp);
    }
    temp = Math.round(temp);

    return temp.toString() + '°' + defaultPrefix;
  }

  private getWeatherLocations(): void {
    this.loading = true;
    this.subscriptions.add(
      this.accountSettingsService.getIncidentDashboardWeatherSettings().subscribe((weatherSettings) => {
        const isWeatherSettingsEmpty = !weatherSettings || !weatherSettings.length;
        if (isWeatherSettingsEmpty) {
          this.handleEmptyWeatherSettings();
        } else {
          this.handleExistingWeatherSettings(weatherSettings);
        }
      })
    );
  }

  private handleEmptyWeatherSettings(): void {
    this.loadingMessage = this.translateService.instant(T.common.finding_your_location_this_may_take_while);
    this.changeDetector.markForCheck();

    this.geoLocationService.getPositionFromGoogleGeolocationAPI().subscribe((response) => {
        if (!this.weatherLocations.length) {
            const userLocation = new LocationViewModel(response.location.lat, response.location.lng, 'Your Location');
            this.weatherLocations.push(userLocation);
            this.weatherLocationSelected = userLocation;
            this.getWeatherForecastData();
        }
    });
  }

  private handleExistingWeatherSettings(weatherSettings: DashboardWeatherLocationViewModel[]): void {
    this.weatherLocations = weatherSettings.map((w) => w.location);
    this.weatherLocationSelected = weatherSettings.find((w) => w.isDefault)?.location || this.weatherLocations.at(0);

    this.getWeatherForecastData();
  }

  private getTemperatureOption() {
    this.subscriptions.add(
      this.employeeSettingsService.getEmployeeSetting(EmployeeSettingTypes.Temperature_Option).subscribe((res) => {
        this.temperatureOption = +res.value;
        this.changeDetector.markForCheck();
      })
    );
  }

  private initMap() {
    this.mapForecastRequestDate = this.nowDateRoundedToNearestHour();

    const lat = this.weatherLocationSelected.latitude;
    const lng = this.weatherLocationSelected.longitude;
    const mapParams = {
      center: new google.maps.LatLng(lat, lng),
      zoom: 6,
      controlSize: 26,
      mapTypeControl: false,
      fullScreenControl: false,
      streetViewControl: false,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT,
        mapTypeIds: ['temp', 'cloud-cover', 'precip-radar', 'precip-forecast', 'wind-speed'],
      },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapParams);
    this.map.mapTypes.set(
      MapLayerId.TEMPERATURE,
      new google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
          const nowTimestamp = Math.round(this.mapForecastRequestDate.valueOf() / 1000);
          return (
            'https://maps-raw.darksky.net/' + nowTimestamp + '/temperature_k/' + zoom + '/' + coord.x + '/' + coord.y + '.jpg'
          );
        },
        tileSize: new google.maps.Size(256, 256),
        name: 'Temperature',
        maxZoom: 11,
      })
    );
    this.map.mapTypes.set(
      MapLayerId.CLOUD_COVERAGE,
      new google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
          const nowTimestamp = Math.round(this.mapForecastRequestDate.valueOf() / 1000);
          return 'https://maps-raw.darksky.net/' + nowTimestamp + '/cloud_cover/' + zoom + '/' + coord.x + '/' + coord.y + '.jpg';
        },
        tileSize: new google.maps.Size(256, 256),
        name: 'Cloud Cover',
        maxZoom: 11,
      })
    );
    this.map.mapTypes.set(
      MapLayerId.PRECIP_RADAR,
      new google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
          return 'https://maps-raw.darksky.net/static/base/' + zoom + '/' + coord.x + '/' + coord.y + '.jpg';
        },
        tileSize: new google.maps.Size(256, 256),
        name: 'Precipitation Radar',
        maxZoom: 11,
      })
    );
    this.map.mapTypes.set(
      MapLayerId.PRECIP_FORECAST,
      new google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
          const nowTimestamp = Math.round(this.mapForecastRequestDate.valueOf() / 1000);
          return (
            'https://maps-raw.darksky.net/' +
            nowTimestamp +
            '/precipitation_intensity_dbz/' +
            zoom +
            '/' +
            coord.x +
            '/' +
            coord.y +
            '.jpg'
          );
        },
        tileSize: new google.maps.Size(256, 256),
        name: 'Precipitation Radar',
        maxZoom: 11,
      })
    );
    this.map.mapTypes.set(
      MapLayerId.WIND_SPEED,
      new google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
          const nowTimestamp = Math.round(this.mapForecastRequestDate.valueOf() / 1000);
          return (
            'https://maps-raw.darksky.net/' + nowTimestamp + '/wind_speed_mps/' + zoom + '/' + coord.x + '/' + coord.y + '.jpg'
          );
        },
        tileSize: new google.maps.Size(256, 256),
        name: 'Wind Speed',
        maxZoom: 11,
      })
    );
    this.map.setMapTypeId(MapLayerId.TEMPERATURE);

    google.maps.event.addListener(this.map, 'click', (event: google.maps.MapMouseEvent) => {
      event.stop;
    });
    google.maps.event.addListener(this.map, 'drag', () => {
      event.stopPropagation();
    });

    this.map.overlayMapTypes.insertAt(0, new BorderdMapType(new google.maps.Size(256, 256)));

    this.createCustomWeatherLayerSwitcher();

    this.loading = false;
    this.changeDetector.detectChanges();
  }

  private style(element: HTMLElement) {
    if (!element) {
      return;
    }

    Array.from(element.children)
      .map((c) => c as HTMLElement)
      .filter((c) => c.offsetWidth)
      .forEach((c) => {
        c.style.display = c.offsetWidth > c.parentElement.offsetWidth ? 'none' : 'inline-block';
      });
  }

  private getWeekDayName(dateMillis: number): string {
    const date = new Date(dateMillis);
    const dayOfWeek = date.getDay();

    let weekDayName = '';
    switch (dayOfWeek) {
      case 0:
        weekDayName = this.translateService.instant(T.calendar.days_of_week.sun);
        break;
      case 1:
        weekDayName = this.translateService.instant(T.calendar.days_of_week.mon);
        break;
      case 2:
        weekDayName = this.translateService.instant(T.calendar.days_of_week.tue);
        break;
      case 3:
        weekDayName = this.translateService.instant(T.calendar.days_of_week.wed);
        break;
      case 4:
        weekDayName = this.translateService.instant(T.calendar.days_of_week.thu);
        break;
      case 5:
        weekDayName = this.translateService.instant(T.calendar.days_of_week.fri);
        break;
      case 6:
        weekDayName = this.translateService.instant(T.calendar.days_of_week.sat);
        break;
      default:
        break;
    }

    return weekDayName;
  }

  private getClassNameForWeatherSummery(summery: string): string {
    let className = '';
    if (summery.toLowerCase().includes('clear')) className = 'clear';
    else if (summery.toLowerCase().includes('broken clouds')) className = 'broken-clouds';
    else if (summery.toLowerCase().includes('scattered clouds')) className = 'scattered-clouds';
    else if (summery.toLowerCase().includes('rain') && !summery.toLowerCase().includes('light rain')) className = 'rain';
    else if (summery.toLowerCase().includes('light rain')) className = 'possible-light-rain';
    else if (summery.toLowerCase().includes('drizzle')) className = 'drizzle';
    else if (summery.toLowerCase().includes('overcast clouds')) className = 'overcast';
    else if (summery.toLowerCase().includes('snow')) className = 'snow';
    else if (summery.toLowerCase().includes('light snow')) className = 'light-snow';

    return className;
  }

  /**
   * Custom dropdown menu to switch between various weather layers.
   */
  private createCustomWeatherLayerSwitcher(): void {
    const weatherLayerLink = function (label: string, onClick: Function): HTMLAnchorElement {
      const el = document.createElement('a');
      el.setAttribute('href', 'javascript:void(0)');
      el.innerHTML = label;
      el.addEventListener('click', () => {
        if (typeof onClick === 'function') {
          onClick();
          document.querySelector('.dropbtn').innerHTML = label;
          el.parentElement.style.display = 'none';
        }
      });

      return el;
    };

    //Layer switcher
    const dropDownDiv = document.createElement('div');
    dropDownDiv.setAttribute('class', 'dropdown');

    const button = document.createElement('button');
    button.setAttribute('class', 'dropbtn');
    button.innerHTML = 'Temperature';
    button.addEventListener('click', (event: MouseEvent) => {
      const currentStyle = dropDownContent.style.display;
      dropDownContent.style.display = currentStyle === 'none' || currentStyle === '' ? 'block' : 'none';
      event.stopPropagation();
    });
    dropDownDiv.appendChild(button);

    const dropDownContent = document.createElement('div');
    dropDownContent.setAttribute('class', 'dropdown-content');

    const tempWeatherAnchor = weatherLayerLink('Temperature', () => {
      this.map.setMapTypeId(MapLayerId.TEMPERATURE);
    });
    const cloudCoverWeatherAnchor = weatherLayerLink('Cloud Cover', () => {
      this.map.setMapTypeId(MapLayerId.CLOUD_COVERAGE);
    });
    const precipitationRadarWeatherAnchor = weatherLayerLink('Precipitation Radar', () => {
      this.map.setMapTypeId(MapLayerId.PRECIP_RADAR);
    });
    const precipitationForecastWeatherAnchor = weatherLayerLink('Precipitation Forecast', () => {
      this.map.setMapTypeId(MapLayerId.PRECIP_FORECAST);
    });

    const windSpeedWeatherAnchor = weatherLayerLink('Wind Speed', () => {
      this.map.setMapTypeId(MapLayerId.WIND_SPEED);
    });

    dropDownContent.appendChild(tempWeatherAnchor);
    dropDownContent.appendChild(cloudCoverWeatherAnchor);
    dropDownContent.appendChild(precipitationRadarWeatherAnchor);
    dropDownContent.appendChild(precipitationForecastWeatherAnchor);
    dropDownContent.appendChild(windSpeedWeatherAnchor);

    dropDownDiv.appendChild(dropDownContent);

    //Forecast shift
    const shiftControlsWrapper = document.createElement('DIV') as HTMLDivElement;
    shiftControlsWrapper.setAttribute('class', 'shift-controls-wrapper');

    const shiftBackwardHoursButton = document.createElement('button');
    shiftBackwardHoursButton.setAttribute('class', 'button');
    shiftBackwardHoursButton.innerText = '3 hrs';
    shiftBackwardHoursButton.addEventListener('click', () => {
      this.shiftMapWeatherLayerForecast(-1 * this.mapWeatherHoursShift);
      this.trickGoogleMapsToUpdateTiles();
      dateTimeLabel.innerText = this.formatMapDateLabel();
    });

    const currentForecastButton = document.createElement('button');
    currentForecastButton.setAttribute('class', 'button');
    currentForecastButton.innerText = 'Now';
    currentForecastButton.addEventListener('click', () => {
      this.mapForecastRequestDate = this.nowDateRoundedToNearestHour();
      this.trickGoogleMapsToUpdateTiles();
      dateTimeLabel.innerText = this.formatMapDateLabel();
    });

    const shiftForwardHoursButton = document.createElement('button');
    shiftForwardHoursButton.setAttribute('class', 'button');
    shiftForwardHoursButton.innerText = '3 hrs';
    shiftForwardHoursButton.addEventListener('click', () => {
      this.shiftMapWeatherLayerForecast(this.mapWeatherHoursShift);
      this.trickGoogleMapsToUpdateTiles();
      dateTimeLabel.innerText = this.formatMapDateLabel();
    });

    shiftControlsWrapper.appendChild(shiftBackwardHoursButton);
    shiftControlsWrapper.appendChild(currentForecastButton);
    shiftControlsWrapper.appendChild(shiftForwardHoursButton);

    //Current Date time display label
    const dateTimeLabel = document.createElement('DIV') as HTMLDivElement;
    dateTimeLabel.setAttribute('class', 'date-time-label');
    dateTimeLabel.innerText = this.formatMapDateLabel();
    //dateTimeLabel.innerText = "30th July, 12:00pm";

    //Controws wrapper to be handled by Google Maps Control API.
    const controlDivWrapper = document.createElement('div');
    controlDivWrapper.setAttribute('class', 'weather-layer-switcher');
    controlDivWrapper.style.marginLeft = '6px';

    controlDivWrapper.appendChild(dropDownDiv);
    controlDivWrapper.appendChild(dateTimeLabel);

    const forecastShiftDivWrapper = document.createElement('div');
    forecastShiftDivWrapper.setAttribute('class', 'weather-layer-switcher');
    forecastShiftDivWrapper.style.marginRight = '38px';
    forecastShiftDivWrapper.style.marginTop = '-32px';
    forecastShiftDivWrapper.appendChild(shiftControlsWrapper);

    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(controlDivWrapper);
    this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(forecastShiftDivWrapper);
  }

  private shiftMapWeatherLayerForecast(hours: number): void {
    if (hours === 0) return;
    if (this.mapForecastRequestDate === null) return;

    this.mapForecastRequestDate.setHours(this.mapForecastRequestDate.getHours() + hours);
  }

  private trickGoogleMapsToUpdateTiles(): void {
    const currentCenter = this.map.getCenter();
    this.map.setCenter(new google.maps.LatLng(0, 0));
    window.setTimeout(() => {
      this.map.setCenter(currentCenter);
    }, 50);
  }

  private nowDateRoundedToNearestHour(): Date {
    const date = new Date();
    const h = date.getHours() + date.getMinutes() / 60 + date.getSeconds() / 3600 + date.getMilliseconds() / 3600000;
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    date.setHours(Math.round(h));

    return date;
  }

  private formatMapDateLabel(): string {
    const currentDate = this.mapForecastRequestDate;

    const hoursTokens: string[] = currentDate.toLocaleString('en', { hour: 'numeric' }).split(' ');
    const daySuffix = this.getOrdinalSuffixOf(currentDate.getDate());
    const strDate =
      currentDate.toLocaleString('en', { day: 'numeric' }) +
      daySuffix +
      ' ' +
      currentDate.toLocaleString('en', { month: 'short' }) +
      ', ' +
      hoursTokens[0] +
      ':00' +
      hoursTokens[1].toLowerCase();

    return strDate;
  }

  /**
   * !!! Maybe this method should be into some util class..
   *
   * The rules are as follows
   *
   * st is used with numbers ending in 1 (e.g. 1st, pronounced first)
   * nd is used with numbers ending in 2 (e.g. 92nd, pronounced ninety-second)
   * rd is used with numbers ending in 3 (e.g. 33rd, pronounced thirty-third)
   *
   * As an exception to the above rules, all the "teen" numbers ending with 11, 12 or 13 use -th (e.g. 11th, pronounced eleventh, 112th, pronounced one hundred [and] twelfth)
   * th is used for all other numbers (e.g. 9th, pronounced ninth).
   * @param monthDay
   */
  private getOrdinalSuffixOf(monthDay: number): string {
    if (monthDay < 0) return 'n/a';

    const j = monthDay % 10;
    const k = monthDay % 100;

    if (j == 1 && k != 11) {
      return 'st';
    }
    if (j == 2 && k != 12) {
      return 'nd';
    }
    if (j == 3 && k != 13) {
      return 'rd';
    }

    return 'th';
  }

  private nth(n: number): string {
    return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
  }

  private celsiusToFarenheit(celsium: number): number {
    return (celsium * 9) / 5 + 32;
  }

  private setSizes() {
    this.adjustWidget();
  }

  private adjustWidget() {
    if (!this.forecastContainer) {
      return;
    }

    const { width, height } = this.forecastContainer.nativeElement.getBoundingClientRect();
    const widgetWidth = width;
    const widgetHeight = height;

    Array.from(this.forecastContainer.nativeElement.classList).forEach((el) => {
      if (el.startsWith('w') || el.startsWith('h')) {
        this.forecastContainer.nativeElement.classList.remove(el);
      }
    });

    if (widgetHeight < 160) {
      this.forecastContainer.nativeElement.classList.add('h160');
    } else if (widgetHeight >= 160 && widgetHeight < 230) {
      this.forecastContainer.nativeElement.classList.add('h230');
    } else if (widgetHeight >= 230) {
      this.forecastContainer.nativeElement.classList.add('h300');
    }

    if (widgetWidth > 0 && widgetWidth <= 450) {
      this.forecastContainer.nativeElement.classList.add('w450');
    } else if (widgetWidth > 450 && widgetWidth <= 550) {
      this.forecastContainer.nativeElement.classList.add('w550');
    } else if (widgetWidth > 550 && widgetWidth <= 650) {
      this.forecastContainer.nativeElement.classList.add('w650');
    } else if (widgetWidth > 650 && widgetWidth <= 750) {
      this.forecastContainer.nativeElement.classList.add('w750');
    } else if (widgetWidth > 750) {
      this.forecastContainer.nativeElement.classList.add('w800');
    }
  }
}

//--------------------------------------------------------------------------------------------
// Used for map tiles.
//--------------------------------------------------------------------------------------------

class BorderdMapType implements google.maps.MapType {
  private static BASE_URL: string = 'https://maps-raw.darksky.net/static/overlay/';
  public tileSize: google.maps.Size;
  public static maxZoom = 11;
  public static alt = '';

  constructor(tileSize: google.maps.Size) {
    this.tileSize = tileSize;
  }
  alt: string;
  maxZoom: number;
  minZoom: number;
  name: string;
  projection: google.maps.Projection;
  radius: number;

  getTile(tileCoord: google.maps.Point, zoom: number, ownerDocument: Document): HTMLImageElement {
    const tile = ownerDocument.createElement('img');
    tile.src = BorderdMapType.BASE_URL + zoom + '/' + tileCoord.x + '/' + tileCoord.y + '.png';
    tile.style.width = this.tileSize.width + 'px';
    tile.style.height = this.tileSize.height + 'px';
    return tile;
  }
  releaseTile(): void {}
}
