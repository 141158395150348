
<div class="carousel-wrapper" (mouseover)="stopAutoplay()" (mouseleave)="startAutoplay()">
  <div class="carousel-header">
    <div class="carousel-header-left">
      <div class="carousel-header-title">Suggested Indicators</div>
      <div class="carousel-header-button">See all</div>
    </div>
    <div class="carousel-header-right">
      <div class="carousel-header-hide-text carousel-header-button">
        Hide
      </div>
      <div class="carousel-header-button">
        <i class="material-icons">close</i>
      </div>
    </div>
  </div>
  <div class="carousel-container">
    <div class="carousel-left-arrow carousel-arrow" (click)="previousPage()">
      <i class="material-icons material-arrow-icon">arrow_back_ios</i>
    </div>
    <div class="carousel-center">
      <div class="carousel-content" [ngClass]="{'slide-in': animateForward, 'slide-out': animateBackward}">
        @for (item of currentItems; track item) {
          @if (objectType === objectTypes.Indicator) {
            <div class="card-test">
              {{ item.title }}
            </div>
          }
          @if (objectType === objectTypes.Risk) {
            <div class="card-test">
              {{ item.title }}
            </div>
          }
        }
      </div>
    </div>
    <div class="carousel-right-arrow carousel-arrow" (click)="nextPage()">
      <i class="material-icons material-arrow-icon">arrow_forward_ios</i>
    </div>
  </div>
  @if (showIndicators) {
    <div class="carousel-footer-indicators">
      @for (indicator of totalPagesArray; track $index;) {
        <div class="carousel-page-button" (click)="goToPage($index)">
          <i class="material-icons" [class.active]="$index === currentPage">radio_button_unchecked</i>
        </div>
      }
    </div>
  }
</div>
