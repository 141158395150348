<div class="row">
  <div
    class="col-12"
    *ngIf="!singleSelect && currentlySelectedFilterSettings && currentlySelectedFilterSettings.length"
  >
    <div class="inline-lozenges-wrapper border-bottom">
      <app-filters-inline-edit-lozenges
        [appliedFilters]="currentlySelectedFilterSettings"
        [filterType]="filterType"
        [label]="label"
        [disabled]="disabled"
        (makePrimary)="onMakePrimary($event)"
        (filterRemoved)="onFilterSettingRemoved($event)"
      >
      </app-filters-inline-edit-lozenges>
    </div>
  </div>

  <div *ngIf="useSearch" class="col-md-12">
    <app-search-dropdown-r
      [objects]="settings"
      [filterType]="filterType"
      [disabled]="disabled"
      [bindProperty]="'filterText'"
      [label]="label"
      [disableMargin]="disableMargin"
      [multiSelect]="!singleSelect"
      [currentlySelectedObject]="currentlySelectedSetting"
      [currentlySelectedObjects]="currentlySelectedFilterSettings"
      [compareBy]="'filterValue'"
      [typeahead]="typeahead$"
      (objectSelected)="onFilterSettingSelected($event)"
      [icon]="icon"
      [svg]="svg"
      [areAllSelected]="areAllSelected"
      (objectCleared)="onFilterSettingCleared()"
      (cancelChanges)="onCancelChanges()"
      (applyChanges)="onApplyChanges($event)"
      (selectAll)="onSelectAll()"
      (clearAll)="onClearAll()"
    >
    </app-search-dropdown-r>
  </div>
  <div *ngIf="!useSearch" class="col-md-12">
    <app-simple-dropdown
      [objects]="settings"
      [filterType]="filterType"
      [disabled]="disabled"
      [bindProperty]="'filterText'"
      [label]="label"
      [disableMargin]="disableMargin"
      [multiSelect]="!singleSelect"
      [currentlySelectedObject]="currentlySelectedSetting"
      [currentlySelectedObjects]="currentlySelectedFilterSettings"
      [compareBy]="'filterValue'"
      [typeahead]="typeahead$"
      (objectSelected)="onFilterSettingSelected($event)"
      [icon]="icon"
      [svg]="svg"
      [areAllSelected]="areAllSelected"
      (objectCleared)="onFilterSettingCleared()"
      (cancelChanges)="onCancelChanges()"
      (applyChanges)="onApplyChanges($event)"
      (selectAll)="onSelectAll()"
      (clearAll)="onClearAll()"
    >
    </app-simple-dropdown>
  </div>
  <!-- <div class="helper-text d-flex" *ngIf="required && valid && !disableRequiredText">Required</div> -->
  <div class="helper-text d-flex text-danger" *ngIf="required && !valid">{{ validationMessage }}</div>
</div>
