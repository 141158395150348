import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { convertToRelativeTimeObject } from '../utilities/date.utilities';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

/**
 * Date pipe calculating the difference between today's date and giving date as an input
 * @param {string} thenDate the date you want to compare with today's date
 * @param {boolean} showHoursMinutesSeconds include seconds in the result
 * @param {boolean} includeEndDate include end date in calculation (1 day is added)
 * @param {boolean} showNumbersOnly show the difference only in numbers
 */
@Pipe({
  name: 'dateDiff',
})
export class DateDiffPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}
  transform(
    receivedDate: string,
    showHoursMinutesSeconds: boolean = true,
    includeEndDate: boolean = true,
    showNumbersOnly: boolean = false,
  ): string {
    const isFutureDate: boolean = moment(receivedDate).isAfter();
    let formattedDiff = '';
    let difference = 0;
    const now = moment();
    const dateToTransform = moment(receivedDate);
    difference = now.diff(dateToTransform);

    const years = Math.abs(now.diff(dateToTransform, 'years'));
    const { days, hours, minutes } = convertToRelativeTimeObject(difference);

    function suffixTheDifferences(type: 'year'|'day'|'hour'|'minute', count: number, instance: DateDiffPipe): string{
     const yearOrDay = (type === 'year' || type === 'day');

     const translationKey = count === 1 ?
     T[yearOrDay ? 'common' : 'calendar'][type]['one']
     : T[yearOrDay ? 'common' : 'calendar'][type]['many'];

      return `${count} ${showNumbersOnly ? '' : instance.translateService.instant(translationKey)}`;
    }

    if (years > 0 && !isFutureDate) {
      formattedDiff = `${years === 1 ? this.translateService.instant(T.sustainability.due_1_year_ago) : this.translateService.instant(T.sustainability.due_count_years_ago, {count: years})}`

      if (days > 0) {
        formattedDiff = this.translateService.instant(T.sustainability.due_years_and_days_ago, {
          countYears: suffixTheDifferences("year", years, this),
          countDays: suffixTheDifferences("day", days, this),
        })
      }

      return formattedDiff.trim();
    }


    if (years > 0 && isFutureDate) {
      return formattedDiff = (`${years === 1 ? this.translateService.instant(T.sustainability.due_in_1_year) : this.translateService.instant(T.sustainability.due_in_count_years, {count: years})}`).trim();
    }


    if (days > 0 && !isFutureDate) {
      if (hours > 0 && !showHoursMinutesSeconds && !isFutureDate) {
        if (includeEndDate) {
          return formattedDiff = (`${days === 1 ? this.translateService.instant(T.sustainability.due_1_day_ago) : this.translateService.instant(T.sustainability.due_count_days_ago, {count: days + 1})}`).trim()
        }
      }

      formattedDiff = `${days === 1 ? this.translateService.instant(T.sustainability.due_1_day_ago) : this.translateService.instant(T.sustainability.due_count_days_ago, {count: days})}`

      // Used in IncidentCardSubtitleComponent
      if (hours > 0 && showHoursMinutesSeconds) {
        formattedDiff = this.translateService.instant(T.calendar.count_hours_and_count_minutes, {
          countHours: suffixTheDifferences("hour", hours, this),
          countMinutes: suffixTheDifferences("minute", minutes, this)
        });
      }


      return formattedDiff.trim();
    }


    if(days > 0 && isFutureDate) {
      return formattedDiff = (`${days === 1 ? this.translateService.instant(T.sustainability.due_in_1_day) : this.translateService.instant(T.sustainability.due_in_count_days, {count: days})}`).trim();
    }


    if (hours > 0 && !isFutureDate) {
      formattedDiff = `${hours === 1 ? this.translateService.instant(T.sustainability.due_1_hour_ago) : this.translateService.instant(T.sustainability.due_count_hours_ago, {count: hours})}`

      // Used in IncidentCardSubtitleComponent
      if (minutes > 0 && showHoursMinutesSeconds) {
        formattedDiff = this.translateService.instant(T.calendar.count_hours_and_count_minutes, {
          countHours: suffixTheDifferences("hour", hours, this),
          countMinutes: suffixTheDifferences("minute", minutes, this)
        });
      }

      return formattedDiff.trim();
    }

    if(hours > 0 && isFutureDate) {
      const dateWithAddedHours = moment().add(hours, 'hours');

      // Check if the date with added hours is the same and show due today if it is
      if(now.date() === dateWithAddedHours.date()) {
        return formattedDiff = this.translateService.instant(T.common.due_today).trim();
      }

      return formattedDiff = this.translateService.instant(T.sustainability.due_in_1_day).trim();
    }


    if (minutes > 0 && showHoursMinutesSeconds) {
      return formattedDiff = (`${minutes === 1 ? this.translateService.instant(T.sustainability.due_1_minute_ago) : this.translateService.instant(T.sustainability.due_count_minutes_ago, {count: minutes})}`).trim();
    } else {
      return formattedDiff = (this.translateService.instant(T.calendar.less_than_a_minute)).trim();
    }
  }
}
