<ng-template #dropdownContentTemplate>
  <div *ngIf="!disabled" #searchDropdownContent class="search-dropdown-content" [style.height]="contentHeight">
    <app-filters-entries-information
      [entriesCount]="filteredObjects.length"
      [selectedEntriesCount]="currentlySelectedObjects ? currentlySelectedObjects.length : 0"
      [matchedEntriesCount]="searchValue ? filteredObjects.length : undefined"
      [isMultiSelect]="multiSelect"
      (selectAll)="onSelectAll()"
      (clearAll)="onClearAll()"
      [areAllSelected]="areAllSelected"
    ></app-filters-entries-information>
    <cdk-virtual-scroll-viewport [itemSize]="dropdownItemHeight" orientation="vertical">
      <app-dropdown-option
      *cdkVirtualFor="let object of filteredObjects"
      [mobile]="mobile"
      [useBorderBottom]="mobile"
      [selected]="objectIsSelected(object)"
      [focused]="isFocused(object)"
      (selectionChange)="onObjectSelected(object)"

      >
      <div style="margin-right: 12px; display: flex" *ngIf="filterType === filterTypes.Job_Priority">
        <span
          *ngFor="let item of [].constructor(3); let i = index"
          style="color: #cccccc"
          class="material-icons-outlined star"
          [class.wetrack-black]="i < object?.filterValue"
          >star
        </span>
      </div>
        <div [innerHTML]="object[bindProperty] | safeHtml"></div>
      </app-dropdown-option>
    </cdk-virtual-scroll-viewport>
    <div class="apply-filters-button-container" *ngIf="mobile && multiSelect">
      <app-button-rectangle text="Apply" [fitToContainer]="true" (clicked)="onApply()"></app-button-rectangle>
    </div>
  </div>
</ng-template>

<ng-template #iconsTemplate let-useExpansionIndicator="useExpansionIndicator">
  <div
    class="search-dropdown-icons"
    [class.search-dropdown-icons-mobile]="mobile"
    [class.search-dropdown-icons-mobile-expanded]="searchDropdownMobileVisible"
    (click)="onIconsClick($event)"
  >
    <div
      class="responsive-flex search-dropdown-icons-close-icon"
      *ngIf="searchValue && !disabled"
      (click)="onClear($event)"
    >
      <i class="material-icons">close</i>
    </div>
    <div *ngIf="!currentlySelectedObject && !disabled" class="responsive-flex mr-0">
      <i class="material-icons">search</i>
    </div>
    <div class="responsive-flex" *ngIf="useExpansionIndicator && !disabled">
      <div class="search-dropdown-icons-arrow"></div>
    </div>
  </div>
</ng-template>

<div class="search-dropdown">
  <ng-template #searchDropdownInput let-isInnerInput="isInnerInput">
    <div
      class="search-dropdown-input"
      [class.search-dropdown-input-mobile]="searchDropdownMobileVisible"
      [class.search-dropdown-input-close-icon-visible]="searchValue"
      [class.search-dropdown-input-expanded]="expanded"
    >
      <app-text-field
        [disabled]="disabled"
        [icon]="icon"
        [svg]="svg"
        *ngIf="!mobile"
        [placeholder]="label"
        ngDropdown
        [disableMargin]="disableMargin"
        [templateRef]="dropdownContentTemplate"
        (dropdownToggled)="onDropdownToggled($event)"
        [adaptToElementWidth]="true"
        [closeUponSelection]="!multiSelect"
        [(ngModel)]="searchValue"
        (ngModelChange)="onInput()"
        (click)="onClick()"
        (blur)="onBlur()"
      ></app-text-field>

      <app-text-field
        [disabled]="disabled"
        [autofocus]="isInnerInput"
        *ngIf="mobile"
        [placeholder]="label"
        [(ngModel)]="searchValue"
        (ngModelChange)="onInput()"
        (click)="onClick()"
        (blur)="onBlur()"
      ></app-text-field>

      <ng-template [ngTemplateOutlet]="iconsTemplate" [ngTemplateOutletContext]="{ useExpansionIndicator: !mobile }">
      </ng-template>
    </div>
  </ng-template>

  <ng-template
    [ngTemplateOutlet]="searchDropdownInput"
    [ngTemplateOutletContext]="{ isInnerInput: false }"
  ></ng-template>

  <div *ngIf="searchDropdownMobileVisible" class="search-dropdown-mobile">
    <app-filters-mobile-header
      *ngIf="mobile && !disabled"
      [title]="'Search'"
      [useClear]="true"
      [clearDisabled]="clearButtonDisabled"
      (cancel)="onCancel()"
      (clear)="onClear($event)"
    ></app-filters-mobile-header>

    <ng-template
      [ngTemplateOutlet]="searchDropdownInput"
      [ngTemplateOutletContext]="{ isInnerInput: true }"
    ></ng-template>

    <app-filters-inline-edit-lozenges
      *ngIf="multiSelect"
      [appliedFilters]="currentlySelectedObjects"
      (filterRemoved)="onObjectSelected($event)"
    >
    </app-filters-inline-edit-lozenges>

    <div class="search-dropdown-mobile-content">
      <ng-template [ngTemplateOutlet]="dropdownContentTemplate"></ng-template>
    </div>
  </div>
</div>
