<button type="button" class="button-transparent" (click)="onClick($event)" [disabled]="disabled" [ngClass]="size">
  <div class="button-content-container" [ngClass]="buttonClasses">
    <i *ngIf="dropdown" class="material-icons" [ngClass]="{ rotate: isOpen }">arrow_drop_down</i>
    <i
      *ngIf="!dropdown && icon !== undefined"
      class="material-icons normal-icon"
      [style.font-size.px]="iconFontSize"
      [style.line-height.px]="iconFontSize"
      >{{ icon }}</i
    >
    <div class="button-text">{{ text }}</div>
  </div>
</button>
