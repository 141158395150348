<div class="logo-upload" (click)="openFileChooserModal()">
  <div class="image-wrapper">
    <ng-container *ngIf="!imageLink">
      <i class="material-icons material-image-icon">image</i>
      <div class="middle" *ngIf="canEdit">
        <i class="material-icons edit-icon">edit</i>
      </div>
    </ng-container>
    <ng-container *ngIf="imageLink">
      <img class="profile-image" [src]="imageLink" [ngClass]="{ rounded: roundCropper }" />
      <div class="middle" *ngIf="canEdit">
        <i class="material-icons edit-icon">edit</i>
      </div>
    </ng-container>
  </div>
</div>
