<div class="list-header-container" *ngIf="!isMobile">
  <ng-container *ngIf="isSearchMode">
    <section class="search-wrapper">
      <div class="d-flex input-field-wrapper">
        <i class="material-icons search-icon" (click)="onSearch()">search</i>
        <app-text-field
          [autofocus]="true"
          [(ngModel)]="searchString"
          [placeholder]="T.search.placeholder_without_objectType | translate"
          (keyup.enter)="onSearch()"
          class="w-100"
        ></app-text-field>
      </div>
      <app-button-transparent (clicked)="hideSearch()" icon="close" class="search-close-btn"></app-button-transparent>
    </section>
  </ng-container>
  <ng-container *ngIf="!isSearchMode">
    <div class="content-container">
      <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
    </div>
    <div class="right-side-wrapper">
      <app-fixed-filter-lozenges *ngIf="showFixedFilters"></app-fixed-filter-lozenges>
      <div class="dividing-line ms-2 me-3" *ngIf="showFixedFilters"></div>
      <ng-container *ngIf="showGlobalFilters">
        <div class="filters-container">
          <app-button-transparent
          class="clear-all-btn"
          *ngIf="appliedFilters.length"
          [text]="T.common.clear_all | translate"
          [icon]="'close'"
          [size]="'small'"
          (click)="onResetFilters()"
          ></app-button-transparent>
          <app-wt-button
          class="open-filters-button ms-2"
          [active]="appliedFilters.length"
          (clicked)="openSideFilters()"
          >
            <div class="d-flex align-items-center">
              <span class="material-icons" style="font-size: 16px;">filter_list</span>
              <span class="ms-1">{{T.defaultLocalizations.filter.many | translate}}</span>
              <div class="applied-filters-count" *ngIf="appliedFilters.length">{{appliedFilters.length}}</div>
            </div>
          </app-wt-button>
        </div>
        <div class="dividing-line ms-3 me-3"></div>
      </ng-container>
      <div class="non-content-container">
        <app-button-rectangle
        *ngIf="availableAddButtons.length > 1"
        (click)="$event.stopPropagation()"
        class="add-button"
        [text]="T.common.add | translate"
        size="small"
        icon="add"
        ngDropdown
        [templateRef]="addDropdown"
        [closeUponSelection]="true"
        ></app-button-rectangle>

        <app-button-rectangle
          *ngIf="availableAddButtons.length === 1"
          (click)="handleAddButtonClick($event)"
          class="add-button"
          [text]="T.common.add | translate"
          size="small"
          icon="add">
        </app-button-rectangle>

        <ng-container *ngIf="useSearch">
          <div *ngIf="availableAddButtons.length > 0"
            class="dividing-line ms-3 me-3">
          </div>
          <app-button-transparent
          class="search-icon"
          (clicked)="showSearch()"
          icon="search"
          textColor="primary-text"
          ></app-button-transparent>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
<div class="mobile-list-header-container" *ngIf="isMobile">
  <div class="first-row">
    <div class="sidebar-toggle-container" (click)="toggleSideNav($event)">
      <div class="open-sidebar-icon" *ngIf="!isSidebarExpanded">
        <i class="material-icons menu-icon">menu</i>
        <span class="notification-counter" *ngIf="pendingNotifications">{{ pendingNotifications }}</span>
      </div>
      <i class="material-icons menu-icon" *ngIf="isSidebarExpanded">close</i>
    </div>
    <div class="content-container ms-2">
      <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
    </div>
    <app-button-icon-square
      *ngIf="showGlobalFilters"
      (clicked)="openSideFilters()"
      [badge]="true"
      [badgeCount]="appliedFilters.length"
      buttonTheme="white"
      class="add-button ms-2"
      size="small"
      icon="filter_list"
    >
    </app-button-icon-square>
    <app-button-icon-square
      *ngIf="!isMobile"
      (click)="$event.stopPropagation()"
      buttonTheme="primary"
      class="add-button ms-2"
      size="small"
      icon="add"
      ngDropdown
      [templateRef]="addDropdown"
      [closeUponSelection]="true"
    >
    </app-button-icon-square>
  </div>
  <div class="second-row" *ngIf="showFixedFilters">
    <app-fixed-filter-lozenges></app-fixed-filter-lozenges>
  </div>
</div>

<ng-template #addDropdown>
  <div class="add-options-container">
      <app-dropdown-option
      *ngFor="let button of availableAddButtons"
      (selectionChange)="button.handler()"
      >{{T.common.add_item | translate: {item: button.localisedItem} }}
    </app-dropdown-option>
  </div>
</ng-template>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
