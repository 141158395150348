import {
  Component,
  OnInit,
  HostListener,
  ChangeDetectorRef,
  ElementRef,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AddModalKeyboardShortcuts } from 'src/app/modules/shared/enums/addModalKeyboardShortcuts.enum';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { RiskDetailsViewModel } from '../../../../risk/models/RiskDetailsViewModel';
import { Employee } from 'src/app/modules/shared/models/employee';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { AddModalButtonOptions } from 'src/app/modules/shared/enums/addModalButtonOptions.enum';
import { RisksService } from '../../../../risk/services/risks.service';
import { ValidatedViewModel } from 'src/app/modules/shared/viewModels/validatedViewModel';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { IconUtilities } from 'src/app/modules/shared/utilities/icon.utilities';
import { IconTypes } from 'src/app/modules/shared/types/iconTypes';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { OperationTypes } from 'src/app/modules/shared/enums/operationTypes';
import { EmployeeRoleTypes } from 'src/app/modules/shared/enums/employees/EmployeeRoleTypes';
import { EmployeeUtil } from 'src/app/modules/shared/utilities/employee.utilities';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { WtValidators } from 'src/app/modules/shared/reactiveValidators/wtValidators';
import { RiskRagHelper } from 'src/app/modules/shared/utilities/risk-rag.utilities copy';
import { RiskRAGMatrixService } from 'src/app/modules/shared/services/risk-rag-matrix.service';
import { RiskLikelihoods } from 'src/app/modules/shared/enums/risks/riskLikelihoods';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { Constants } from 'src/app/modules/shared/models/constants';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { RiskTypes } from 'src/app/modules/risk/enums/riskTypes';
import { RiskStatusTypes } from 'src/app/modules/risk/enums/riskStatusTypes';
import { RiskStrategyTypes } from 'src/app/modules/risk/enums/riskStrategyTypes';

@Component({
  selector: 'app-risk-add-modal',
  templateUrl: './risk-add-modal.component.html',
  styleUrls: ['./risk-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RiskAddModalComponent implements OnInit, OnDestroy {
  public risk: RiskDetailsViewModel = new RiskDetailsViewModel();
  public filters: FilterViewModel[] = [];
  public filterTypes = FilterTypes;
  public isLoading = false;
  public isHiddenOptionVisible = false;
  private mobileMaxWidth = Constants.sm;
  public isMobile: boolean = window.innerWidth < this.mobileMaxWidth;
  public selectedRiskType: RiskTypes = RiskTypes.Risk;
  public form: UntypedFormGroup;
  public dateReseter = false;
  public addModalButtonOptions = AddModalButtonOptions;
  public buttonOptions = AddModalButtonOptions.getOptions(this.translateService);
  public keyboardShortcuts = AddModalKeyboardShortcuts.getKeyShortsAsObj(this.translateService);
  public editableFieldTypes = EditableFieldTypes;
  public likelihoodValue = RiskLikelihoods.Highly_Unlikely;
  public impactValue = 1;
  public statusValue = 1;
  public excludeDropdownValuesArr = [];
  public exludeRiskStrategyArr = [];
  public readonly riskTypes = RiskTypes;
  public triggerErrors = false;
  public riskStrategyValue = 3;
  public validationErrors: string[];
  ragDescription: string;
  ragColour: string;

  public initialSelectedProject: FilterViewModel;
  public initialSelectedType;
  public hasRiskManagerPermission = false;
  public exludeRiskStatuses: number[] = [];
  svg = IconUtilities.getSvgForIconType(IconTypes.Risk);

  private isTouched = false;
  private allowedFilters: FilterViewModel[];
  private subscriptions = new Subscription();
  public employee: Employee;
  public localisedDepartment: string = 'Department';
  objectTypes = ObjectTypes;
  public readonly T = T;
  localisedImpact = 'Impact';

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      event.preventDefault();
      if (event.ctrlKey || event.metaKey) {
        if (event.shiftKey) {
          this.handleAction(
            AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
          );
          return;
        }
        this.handleAction(
          AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_View_Details, this.translateService)
        );
        return;
      }
      this.handleAction(AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Close, this.translateService));
      return;
    }
  }

  @HostListener('window:resize', ['$event']) onWindowResize(event) {
    this.isMobile = window.innerWidth < this.mobileMaxWidth;
    this.changeDetectorRef.detectChanges();
  }

  constructor(
    private bsModalRef: BsModalRef,
    private router: Router,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private elemRef: ElementRef,
    private allowedFiltersService: AllowedFiltersService,
    private authenticationService: AuthenticationService,
    private riskService: RisksService,
    private readonly localisationService: LocalisationService,
    private readonly riskRAGMatrixService: RiskRAGMatrixService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly wtValidators: WtValidators
  ) {}

  ngOnInit(): void {
    this.localisedImpact = this.localisationService.localiseFilterType(FilterTypes.Risk_Impact);
    this.isMobile = window.innerWidth < this.mobileMaxWidth;
    this.employee = this.authenticationService.getCurrentEmployee();
    this.hasRiskManagerPermission =
      EmployeeUtil.hasRole(this.employee, EmployeeRoleTypes.Risk_Manager) || EmployeeUtil.IsAdmin(this.employee);
    this.localisedDepartment = this.localisationService.localiseObjectType(ObjectTypes.Department);
    if (!this.hasRiskManagerPermission) {
      this.exludeRiskStatuses = [RiskStatusTypes.Open, RiskStatusTypes.Closed];
    }

    this.initNewForm();

    this.subscriptions.add(
      this.form.valueChanges.subscribe((res) => {
        this.isTouched = true;
      })
    );

    this.initAllowedFilters();

    if (!this.initialSelectedType) {
      this.selectType(this.riskTypes.Risk);
    } else {
      this.selectType(this.initialSelectedType);
    }

    this.setRAGInformation();
  }

  initNewForm() {
    this.form = this.fb.group({
      riskTitle: [
        '',
        { validators: [Validators.required, this.wtValidators.title(), this.wtValidators.restrictedChars([';'])], updateOn: 'blur' }
      ],
      description: ['', Validators.maxLength(2000)],
      financialImpact: ['', Validators.maxLength(20)]
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setLikelihoodFilter(likelihood: RiskLikelihoods) {
    const filters = this.filters.filter((f) => f.filterType != FilterTypes.Risk_Likelihood);
    const newLikelihoodFilter = this.allowedFiltersService
      .getCachedAllowedFiltersByType(FilterTypes.Risk_Likelihood)
      .find((f) => f.filterValue == likelihood);
    this.filters.filter((x) => x.filterType == FilterTypes.Risk_Likelihood);
    this.filters = [...filters, newLikelihoodFilter];
  }

  selectType(type: RiskTypes) {
    this.exludeRiskStrategyArr = [];

    switch (type) {
      case RiskTypes.Risk:
        {
          this.selectedRiskType = RiskTypes.Risk;
          this.likelihoodValue = RiskLikelihoods.Highly_Unlikely;
          this.setLikelihoodFilter(RiskLikelihoods.Highly_Unlikely);
          this.excludeDropdownValuesArr = [RiskLikelihoods.Issue];

          this.changeDetectorRef.detectChanges();
          this.riskStrategyValue = RiskStrategyTypes.Mitigate;
          this.svg = IconUtilities.getSvgForIconType(IconTypes.Risk, 24, true);
        }
        break;
      case RiskTypes.Issue:
        this.selectedRiskType = RiskTypes.Issue;
        this.likelihoodValue = RiskLikelihoods.Issue;
        this.excludeDropdownValuesArr = RiskLikelihoods.numValues().filter((v) => v !== RiskLikelihoods.Issue);
        this.setLikelihoodFilter(RiskLikelihoods.Issue);

        this.changeDetectorRef.detectChanges();
        this.riskStrategyValue = RiskStrategyTypes.Contingency;

        this.svg = IconUtilities.getSvgForIconType(IconTypes.Issue);
        break;
      case RiskTypes.Opportunity:
        {
          this.selectedRiskType = RiskTypes.Opportunity;
          this.likelihoodValue = RiskLikelihoods.Highly_Unlikely;
          this.setLikelihoodFilter(RiskLikelihoods.Highly_Unlikely);
          this.excludeDropdownValuesArr = [RiskLikelihoods.Issue];

          this.changeDetectorRef.detectChanges();
          this.riskStrategyValue = RiskStrategyTypes.Exploit;
          this.svg = IconUtilities.getSvgForIconType(IconTypes.Opportunity);
        }
        break;
      default:
        break;
    }
  }

  validateRequiredFilters(filtersForSearch: FilterTypes[]): boolean {
    let isValid = true;
    filtersForSearch.forEach((currentFilterType) => {
      if (!this.filters.find((f) => f.filterType === currentFilterType)) {
        isValid = false;
      }
    });

    return isValid;
  }

  get getSelectedRiskName() {
    return this.localisationService.localise(RiskTypes[this.selectedRiskType]);
  }

  get getDueDate() {
    if (this.risk.dueDate) {
      return this.risk.dueDate;
    }
    return '';
  }

  setRAGInformation() {
    this.risk.rag = this.riskRAGMatrixService.getRAGFromImpactAndLikelihood(this.impactValue, this.likelihoodValue);
    this.ragDescription = this.localisationService.localiseFilterValueByFilterType(this.risk.rag, this.filterTypes.Risk_RAG);
    this.ragColour = RiskRagHelper.getRAGColourHexFromValue(this.risk.rag);
    this.changeDetectorRef.markForCheck();
  }

  setDueDate(dateAsIsoString: string) {
    this.risk.dueDate = dateAsIsoString;
    this.isTouched = true;
  }

  showHiddenOptions() {
    this.isHiddenOptionVisible = !this.isHiddenOptionVisible;
    this.changeDetectorRef.detectChanges();
  }

  get hideMoreOptions() {
    if (this.isMobile) return false;
    return !this.isHiddenOptionVisible;
  }

  setAccountForCurrentOwner() {
    if (this.allowedFilters) {
      const empFilter = this.allowedFilters.find(
        (s: any) => s.filterType === FilterTypes.Owner && s.filterValue.toString() === this.employee.id.toString()
      );
      if (empFilter !== null && this.filters.indexOf(empFilter) < 0) {
        this.filters = this.filters.slice();
        this.filters.push(empFilter);
      }
    }
    this.changeDetectorRef.detectChanges();
  }
  setMyDepartment() {
    if (this.allowedFilters) {
      const empFilter = this.allowedFilters.find(
        (f) => f.filterType === FilterTypes.Department && f.filterValue.toString() === this.employee.departmentId.toString()
      );
      if (!this.filters.some((x) => x.id == empFilter.id)) {
        if (empFilter && this.filters.indexOf(empFilter) < 0) {
          this.filters = this.filters.slice();
          this.filters.push(empFilter);
        }
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  handleAction(action: string) {
    const isFiltersValid = this.validateRequiredFilters([
      FilterTypes.Owner,
      FilterTypes.Risk_Status,
      FilterTypes.Risk_Likelihood,
      FilterTypes.Risk_Impact
    ]);
    this.validationErrors = [];
    if (!this.form.valid || !isFiltersValid) {
      this.triggerErrors = true;
      return;
    }

    this.isLoading = true;

    this.subscriptions.add(
      this.handleSubmit().subscribe((res: ValidatedViewModel) => {
        const newRisk = res.returnModel as RiskDetailsViewModel;
        const errors = res.errorList;
        if (errors?.length > 0) {
          this.validationErrors = errors;
          this.isLoading = false;
          this.changeDetectorRef.markForCheck();
        } else {
          this.allowedFiltersService.refreshFilters(
            OperationTypes.Create,
            [{ id: newRisk.id, title: newRisk.title, relatedObjectId: 0 }],
            FilterTypes.Risk
          );
          this.isLoading = false;
          void this.alertService.success(
            this.translateService.instant(T.common.item_added, { item: this.localiseRiskType(this.selectedRiskType) })
          );

          if (action === AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Close, this.translateService)) {
            this.closeModal();
          } else if (
            action ===
            AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_View_Details, this.translateService)
          ) {
            const contextRoute = RiskTypes[this.selectedRiskType].toLowerCase();
            const currentTask = res.returnModel;
            void this.router.navigate([`v2/risk/risks-issues/${contextRoute}/${currentTask.id}`]);
            this.closeModal();
          } else if (
            action ===
            AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
          ) {
            this.resetModal();
          }
        }
      })
    );
  }

  handleFilterChange(filters: FilterViewModel[]) {
    this.filters = filters;
    this.impactValue = filters.find((f) => f.filterType === FilterTypes.Risk_Impact)?.filterValue;
    this.statusValue = filters.find((f) => f.filterType === FilterTypes.Risk_Status)?.filterValue;
    this.riskStrategyValue = filters.find((f) => f.filterType === FilterTypes.Risk_Strategy)?.filterValue;
    this.likelihoodValue = filters.find((f) => f.filterType === FilterTypes.Risk_Likelihood)?.filterValue;

    // Use this aproach because afterViewInit is triggered before emmiting the initial filters.
    // 4 are the initial filters, so thats the hacky way instead of AfterViewInit
    if (filters.length > 4) {
      this.isTouched = true;
    }

    this.changeDetectorRef.detectChanges();
    this.setRAGInformation();
  }

  resetModal() {
    this.form.reset();
    this.initNewForm();
    this.filters = [];
    this.resetDateComponent();
  }

  resetDateComponent() {
    this.dateReseter = true;
    this.changeDetectorRef.detectChanges();
    this.dateReseter = false;
    this.changeDetectorRef.detectChanges();
  }

  handleSubmit() {
    this.risk.filters = this.filters;
    // Get form values
    this.risk.description = this.form.controls.description.value.trim();
    this.risk.title = this.form.controls.riskTitle.value.trim();
    // this.risk.financialImpact = this.form.controls.financialImpact.value.trim();

    // Populate primitive properties from Filter list
    this.risk.type = this.selectedRiskType;
    this.risk.privacyStatus = this.getFilterValue(FilterTypes.Risk_Privacy_Status);
    this.risk.impact = this.getFilterValue(FilterTypes.Risk_Impact);
    this.risk.likelihood = this.getFilterValue(FilterTypes.Risk_Likelihood);
    this.risk.riskStrategyId = this.getFilterValue(FilterTypes.Risk_Strategy);
    this.risk.ownerId = this.getFilterValue(FilterTypes.Owner);
    this.risk.createdById = this.employee.id;
    this.risk.identifiedById = this.getFilterValue(FilterTypes.Identified_By);
    this.risk.status = this.getFilterValue(FilterTypes.Risk_Status);

    return this.riskService.add(this.risk);
  }

  getFilterValue(filterType: FilterTypes): number {
    const filter: FilterViewModel = this.risk?.filters?.find((f) => f.filterType === filterType);
    return filter ? filter.filterValue : 0;
  }
  closeModal() {
    this.bsModalRef.hide();
  }

  initAllowedFilters() {
    this.allowedFilters = this.allowedFiltersService.getCachedAllAllowedFilters();
    this.initInitalSelectedFields();
  }

  confirmClose() {
    if (this.isTouched) {
      this.confirmationService.confirmThis(
        this.translateService.instant(T.common.are_sure_you_want_to_leave_page),
        () => {
          this.closeModal();
        },
        () => {
          //handle cancel
        },
        this.translateService.instant(T.common.confirm),
        false,
        this.translateService.instant(T.common.stay),
        this.translateService.instant(T.common.leave),
        'danger'
      );
    } else {
      this.closeModal();
    }
  }

  updateDescription($event) {
    this.form.controls.description.setValue($event);
  }

  initInitalSelectedFields() {
    if (this.initialSelectedProject) {
      const filteredprj = this.allowedFilters.find(
        (f) =>
          f.filterType == FilterTypes.Project && f.filterValue.toString() == this.initialSelectedProject.filterValue.toString()
      );
      this.filters.push(filteredprj);
    }

    this.filters = FilterUtilities.SetFilterValue(this.filters, FilterTypes.Risk_Impact, 1, ObjectTypes.Risk);
    this.filters = FilterUtilities.SetFilterValue(this.filters, FilterTypes.Risk_Status, 1, ObjectTypes.Risk);
    this.filters = FilterUtilities.SetFilterValue(
      this.filters,
      FilterTypes.Risk_Likelihood,
      this.likelihoodValue,
      ObjectTypes.Risk
    );

    this.filters = this.filters.slice();
    this.changeDetectorRef.markForCheck();
  }

  localiseString(s: string) {
    return this.localisationService.localise(s);
  }

  public localiseRiskType(riskType: RiskTypes): string {
    switch (riskType) {
      case RiskTypes.Risk:
        return this.localisationService.localiseObjectType(ObjectTypes.Risk);
      case RiskTypes.Issue:
        return this.translateService.instant(T.defaultLocalizations.issue.one);
      case RiskTypes.Opportunity:
        return this.translateService.instant(T.defaultLocalizations.opportunity.one);
      default:
        return 'unknown';
    }
  }
}
