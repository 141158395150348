import { ModifiableEntityViewModel } from "../../incidents/viewModels/modifiableEntityViewModel";
import { RiskAssesmentPrivacyStatuses } from "../../shared/enums/planning/riskAssesmentPrivacyStatuses";
import { RiskAssesmentStatuses } from "../../shared/enums/planning/riskAssesmentStatuses";

export class RiskAssesmentViewModel extends ModifiableEntityViewModel {
  startDate: string;
  dueDate: string;
  ownerId: number;
  approverId: number;
  status: RiskAssesmentStatuses;
  privacyStatus: RiskAssesmentPrivacyStatuses;
  eventId: number;
  description: string;
  closed: string;
}
