import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  HostListener,
  OnInit,
  OnChanges,
} from '@angular/core';
import { Constants } from 'src/app/modules/shared/models/constants';
import { AuthenticationService } from '../../../services/authentication.service';
import { Employee } from '../../../models/employee';
import { EditableFieldTypes } from '../../../enums/editableFieldTypes';
import { ValidationUtilities } from '../../../utilities/validation.utilities';
import { UserAgentService } from '../../../services/user-agent.service';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-details-editable-field',
  templateUrl: './details-editable-field.component.html',
  styleUrls: ['./details-editable-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsEditableFieldComponent implements OnInit, OnChanges {
  @ViewChild('cardText') cardText: ElementRef<HTMLElement>;
  @ViewChild('fieldWrapper') fieldWrapper: ElementRef<HTMLElement>;

  @Input() input: string;
  @Input() header: string;
  @Input() type: EditableFieldTypes;
  @Input() canEdit: boolean;
  @Input() placeholder: string;
  @Input() maxLength = 80;
  @Input() minLength = 3;
  @Input() headerFontSize: string;
  @Input() required = true;
  @Input() accordionLozenge = false;
  @Input() fieldIsPhoneNumber: boolean = false;
  @Input() emptyStateMessage: string = this.translateService.instant(T.common.click_to_add_description);
  /**
   * If true, the edit button will not be shown.But the edit mode can be toggled by clicking on the field.
   */
  @Input() preventEditButtonToBeShown = false;

  @Output() onUpdate = new EventEmitter<string>();
  @Output() editModeChange = new EventEmitter<boolean>();
  @Output() onInputChange = new EventEmitter<string>();

  @HostListener('mousedown', ['$event'])
  mouseDownInside(event: MouseEvent) {
    if (this.type !== EditableFieldTypes.RichText) {
      if (!(this.cardText && this.cardText.nativeElement.contains(event.target as HTMLElement))) {
        event.stopPropagation();
      }

      this.hasClickStartedInside = true;
    }
  }

  @HostListener('document:mouseup', ['$event'])
  mouseUpAnywhere(event: MouseEvent) {
    if (this.type !== EditableFieldTypes.RichText) {
      const eventTarget = event.target as HTMLElement;
      const fieldWrapperEl = this.fieldWrapper.nativeElement;

      if (fieldWrapperEl.contains(eventTarget) || fieldWrapperEl === eventTarget) {
        event.stopPropagation();
      }

      if (this.hasClickStartedInside) {
        this.hasClickStartedInside = false;
      } else {
        this.cancelEdit();
      }
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (!this.input || !this.input.length || !this.fieldIsValid) {
      event.stopPropagation();
    } else if (this.type !== EditableFieldTypes.RichText && this.editMode) {
      this.onSave(event);
    }
  }

  // public
  editMode = false;
  showEditButton = false;
  employee: Employee;
  fieldTypes = EditableFieldTypes;
  originalInput: string;
  isTouchDevice = false;
  hasClickStartedInside = false;
  public readonly T = T;


  constructor(
    public readonly elementRef: ElementRef<HTMLElement>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly userAgentService: UserAgentService,
    private readonly authenticationService: AuthenticationService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    if (!this.canEdit) {
      this.emptyStateMessage = this.translateService.instant(T.defaultLocalizations.description.none);
    }
    this.employee = this.authenticationService.getCurrentEmployee();
    this.originalInput = JSON.parse(JSON.stringify(this.input));

    this.userAgentService.isTouch$.subscribe((isTouch) => {
      this.isTouchDevice = isTouch;
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnChanges() {
    this.originalInput = JSON.parse(JSON.stringify(this.input));
  }

  toggleEditMode(e?: Event) {
    e.stopPropagation();
    if (this.canEdit) {
      this.editMode = !this.editMode;
      this.editModeChange.emit(this.editMode);
      this.changeDetectorRef.markForCheck();
    }
  }

  toggleShowEditButton() {
    if (!this.editMode && this.canEdit) {
      this.showEditButton = !this.showEditButton;
      this.changeDetectorRef.markForCheck();
    }
  }

  get isMobile(): boolean {
    return document.body.clientWidth <= Constants.sm;
  }

  onSave(event) {
    event.stopPropagation();
    this.onUpdate.next(this.input);
    this.editMode = false;
    this.editModeChange.next(this.editMode);
    this.showEditButton = false;
    this.changeDetectorRef.markForCheck();
  }

  onRichEditorSave(input: string) {
    this.input = input;
    this.onUpdate.next(this.input);
    this.editMode = false;
    this.showEditButton = false;
    this.changeDetectorRef.markForCheck();
  }

  cancelEdit(event?) {
    if (this.input !== this.originalInput) {
      this.input = JSON.parse(JSON.stringify(this.originalInput)).trim();
    }
    event?.stopPropagation();
    this.showEditButton = false;
    this.editMode = false;
    this.editModeChange.next(this.editMode);
    this.changeDetectorRef.markForCheck();
  }

  changeInput(newValue: string) {
    this.input = newValue;
    this.onInputChange.next(newValue);
    this.changeDetectorRef.markForCheck();
  }

  get fieldIsValid(): boolean {
    let result = true;

    if (this.required) {
      result = ValidationUtilities.validateAgainstMinLength(this.input.trim());
    }

    if (this.type === EditableFieldTypes.Card || this.type === EditableFieldTypes.Header) {
      const forbiddenChars = [';', '<', '>', '!'];
      result = !!(!forbiddenChars.some((el) => this.input.trim().includes(el)) && this.input.trim().length);
    }

    if (this.fieldIsPhoneNumber) {
      const phoneRegex = /^\+[1-9]\d{1,14}$/;
      result = phoneRegex.test(this.input);
    }

    return result;
  }
}
