<ng-container *ngIf="loading; else notLoading">
  <app-loading [displayLogo]="false">
  </app-loading>
</ng-container>

<ng-template #notLoading>
  <main class="mt-2 full-width chat-container custom-scrollbar">
    <div>
      <section class="d-flex mt-4 full-width" *ngFor="let chatMessage of chatMessages">
        <aside class="avatar">
          <span class="material-icons">
          account_circle
          </span>
        </aside>
        <article class="full-width">
          <div class="chat-message-header d-flex align-items-center justify-content-between full-width mb-1">
            <span class="chat-message-sender"> {{ chatMessage.from }} </span>
            <span class="chat-message-time"> {{ chatMessage.created | timeAgo }} </span>
          </div>
          <div class="chat-message-receiver full-width mb-2">
            <span class="chat-message-to">To:</span>
            <span>{{ chatMessage.to }}</span>
          </div>
          <div
            [innerHTML]="chatMessage.message | safeHtml"
            class="chat-message full-width">
          </div>
        </article>
      </section>
    </div>
    <section class="mt-5 response-field" #responseField>
      <div class="d-flex align-items-center">
        <app-text-field
          class="full-width"
          [(ngModel)]="message"
          [placeholder]="T.common.type_reponse | translate"
          [removeBorders]="true"
          [maxLength]="1600">
        </app-text-field>
        <i
          [closeUponSelection]="true"
          ngDropdown
          [templateRef]="savedTextsTemplate"
         class="material-icons add-saved-response-icon">app_registration</i>
      </div>


      <div class="d-flex justify-content-end">
        <button (click)="sendResponse()" class="send-btn">
          <i class="material-icons">send</i>
        </button>
      </div>
    </section>
  </main>
</ng-template>


<ng-template #savedTextsTemplate>
  <div class="dropdown-options-container custom-scrollbar">
    <h5 class="dropdown-title">{{ T.common.shortcuts | translate }}</h5>
    <div
      *ngFor="let textMsg of savedTextMessages"
      class="dropdown-option"
      (click)="addSavedResponse(textMsg)"
    >
      {{ textMsg.title }}
    </div>
  </div>
</ng-template>
