import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { EventTypeListItemViewModel } from '../viewModels/eventCategoryListItemViewModel';
import { EventListItemViewModel } from '../viewModels/eventListItemViewModel';
import { EventTypeDetailsViewModel } from '../viewModels/eventCategoryDetailsViewModel';
import { EventDetailsViewModel } from '../viewModels/eventDetailsViewModel';

@Injectable()
export class EventsService {
  private baseUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration) {
    this.baseUrl = this.configuration.buildEndpoint('Events/');
  }

  addEvent(event: EventDetailsViewModel) {
    //hack so we can save events, Gavril will fix that
    event['translateService'] = null;
    event.dashboardWidgetInfo['translateService'] = null;

    return this.http.post<EventDetailsViewModel>(`${this.baseUrl}AddEvent`, event);
  }

  editEvent(event: EventDetailsViewModel) {
    return this.http.post<EventDetailsViewModel>(`${this.baseUrl}EditEvent`, event);
  }

  getEventDetails(eventId: number): Observable<EventDetailsViewModel> {
    return this.http.get<EventDetailsViewModel>(`${this.baseUrl}GetEventDetails/${eventId}`);
  }

  getEventist(): Observable<EventListItemViewModel[]> {
    return this.http.get<EventListItemViewModel[]>(this.baseUrl + 'GetEventsList');
  }

  getLiveEvents(): Observable<EventDetailsViewModel[]> {
    return this.http.get<EventDetailsViewModel[]>(this.baseUrl + 'GetLiveEvents');
  }

  archiveEvent(event: EventDetailsViewModel) {
    return this.http.post<EventDetailsViewModel>(`${this.baseUrl}ArchiveEvent`, event);
  }

  deleteEvents(Ids: number[]): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}DeleteEvents`, Ids);
  }

  getEventTypeList(): Observable<EventTypeListItemViewModel[]> {
    return this.http.get<EventTypeListItemViewModel[]>(this.baseUrl + 'GetEventTypesList');
  }

  addEventType(eventCategory: EventTypeDetailsViewModel) {
    return this.http.post<EventTypeDetailsViewModel>(`${this.baseUrl}AddEventType`, eventCategory);
  }

  editEventType(eventCategory: EventTypeDetailsViewModel) {
    return this.http.post<EventTypeDetailsViewModel>(`${this.baseUrl}EditEventType`, eventCategory);
  }

  deleteEventTypes(Ids: number[]): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}DeleteEventTypes`, Ids);
  }
}
