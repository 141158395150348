<div class="quick-filters-container" *ngIf="!isMobile">
  <ng-container [ngTemplateOutlet]="leftSideTemplate"></ng-container>
  <app-button-rectangle
    *ngIf="showAddButton"
    [text]="T.common.add | translate"
    [icon]="'add'"
    (clicked)="onAddBtnClick()"
  ></app-button-rectangle>
  <div class="divider-line" *ngIf="showAddButton"></div>
  <app-list-search-input-fixed
    [background]="'var(--wt-neutral-extra-light)'"
    [placeholder]="searchPlaceholder"
    [value]="searchText"
    (inputChanged)="onListSearch($event)"
  ></app-list-search-input-fixed>
  <ng-container *ngIf="showSuggestedFilters">
    <div class="divider-line"></div>
    <div class="suggested-filters-section">
      <app-wt-button ngDropdown #favFiltersDropdown="ngDropdown" [templateRef]="favoriteFilters" [templateOutletContext]="{dropdownRef: favFiltersDropdown}" [active]="!!favouriteMatchingFilter">
        <div class="d-flex align-items-center">
          <span class="material-icons" style="font-size: 16px; color: var(--wt-primary);">bookmark_outline</span>
          <span class="ms-1">{{favouriteMatchingFilter ? favouriteMatchingFilter.title : (T.common.saved_filters | translate)}}</span>
        </div>
      </app-wt-button>
    </div>
  </ng-container>
  <ng-container *ngIf="showQuickFiltersForFilterSelectors.length">
    <div class="divider-line"></div>
  </ng-container>
  <div class="quick-filters-section">
    <ng-container *ngFor="let filterSelector of showQuickFiltersForFilterSelectors">
      <app-select
        *ngIf="filterSelector.filterType !== filterTypes.Date"
        ngDropdown
        #quickFilterDropdown="ngDropdown"
        [closeUponSelection]="false"
        [templateRef]="quickFilterContent"
        [value]="getSelectedValues(filterSelector)"
        [label]="filterSelector.filterTypeText"
        [templateOutletContext]="{filterSelector: filterSelector, dropdownRef: quickFilterDropdown}"
        [counter]="selectedFiltersCountForFilterType(filterSelector)"
        [isActive]="hasSelectedFiltersForFilterType(filterSelector)"
        [isFocused]="quickFilterDropdown.visible"
        (dropdownToggled)="onQuickFilterDropdownToggled($event)"
      >
      </app-select>
      <app-select
        *ngIf="filterSelector.filterType === filterTypes.Date"
        ngDropdown
        #quickDateFilterDropdown="ngDropdown"
        [closeUponSelection]="true"
        [templateRef]="quickDateFilterContent"
        [value]="getDateSelectValue(filterSelector)"
        [label]="filterSelector.filterTypeText"
        [customSelectSpacer]="getDateSelectValue(filterSelector)"
        [templateOutletContext]="{filterSelector: filterSelector, dropdownRef: quickDateFilterDropdown}"
        [isActive]="hasSelectedFiltersForFilterType(filterSelector)"
        [isFocused]="quickDateFilterDropdown.visible"
        (dropdownToggled)="onQuickFilterDropdownToggled($event)"
      >
      </app-select>
    </ng-container>
  </div>
  <div class="divider-line"></div>
  <div class="filter-actions-section">
    <app-button-transparent
    class="clear-all-btn"
    *ngIf="appliedFilters.length"
    [text]="T.common.clear_all | translate"
    [icon]="'close'"
    [size]="'small'"
    (click)="onResetFilters()"
    ></app-button-transparent>
    <app-wt-button
    class="open-filters-button ms-2"
    [active]="appliedFilters.length"
    (clicked)="openSideFilters()"
    >
      <div class="d-flex align-items-center">
        <span class="material-icons" style="font-size: 16px;">filter_list</span>
        <span class="ms-1">{{T.defaultLocalizations.filter.many | translate}}</span>
        <div class="applied-filters-count" *ngIf="appliedFilters.length">{{appliedFilters.length}}</div>
      </div>
    </app-wt-button>
    <app-button-icon-square
      *ngIf="showSettingsButton"
      icon="settings"
      buttonTheme="white"
      (clicked)="onSettingsBtnClick()"
      class="mr-8"
    ></app-button-icon-square>
    <ng-container [ngTemplateOutlet]="rightSideTemplate"></ng-container>
  </div>
  <div class="empty-filters-message-container" *ngIf="showEmptyFiltersMessage && !appliedFilters.length">
    <i class="material-icons">info</i>
    <span class="empty-message">{{T.common.empty_filters_message | translate}} </span>
  </div>
</div>
<div class="mobile-quick-filters-container" *ngIf="isMobile">
  <div class="first-row">
    <app-button-rectangle
      *ngIf="showAddButton"
      [text]="T.common.add | translate"
      [icon]="'add'"
      (clicked)="onAddBtnClick()"
    ></app-button-rectangle>
    <div class="divider-line" *ngIf="showAddButton"></div>
    <app-list-search-input-fixed
      [background]="'var(--wt-neutral-extra-light)'"
      [placeholder]="searchPlaceholder"
      [value]="searchText"
      (inputChanged)="onListSearch($event)"
    ></app-list-search-input-fixed>
    <div class="filter-actions-section">
      <!-- <app-button-transparent
      class="clear-all-btn"
      *ngIf="appliedFilters.length"
      [text]="T.common.clear_all | translate"
      [icon]="'close'"
      [size]="'small'"
      (click)="onResetFilters()"
      ></app-button-transparent> -->
      <app-wt-button
      class="open-filters-button ms-2"
      [active]="appliedFilters.length"
      (clicked)="openSideFilters()"
      >
        <div class="d-flex align-items-center">
          <span class="material-icons" style="font-size: 16px;">filter_list</span>
          <span class="ms-1">{{T.defaultLocalizations.filter.many | translate}}</span>
          <div class="applied-filters-count" *ngIf="appliedFilters.length">{{appliedFilters.length}}</div>
        </div>
      </app-wt-button>
      <app-button-icon-square
        *ngIf="showSettingsButton"
        icon="settings"
        buttonTheme="white"
        (clicked)="onSettingsBtnClick()"
        class="mr-8"
      ></app-button-icon-square>
    </div>
  </div>
  <div class="second-row">
    <ng-container [ngTemplateOutlet]="leftSideTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="rightSideTemplate"></ng-container>
    <!-- <ng-content select="[left-side]"></ng-content>
    <ng-content select="[right-side]"></ng-content> -->
  </div>
</div>


<ng-template #favoriteFilters let-dropdownRef="dropdownRef">
  <div class="favorite-filters-container">
    <div class="favorite-filters-body">
      <ng-container *ngIf="favoriteFiltersForObjectTypes.length">
        <div class="favorite-filter-option" *ngFor="let favFilter of favoriteFiltersForObjectTypes">
          <app-radio-button
          [text]="favFilter.title"
          [checked]="isFavoriteFilterSelected(favFilter)"
          (checkedChanged)="selectFavoriteFilter(favFilter)"
          ></app-radio-button>
          <div class="filter-actions">
            <span class="material-icons edit-btn" (click)="onEditFavoriteFilter(favFilter); dropdownRef.onEscape()">edit</span>
            <span class="material-icons delete-btn" (click)="onDeleteFavoriteFilter(favFilter); dropdownRef.onEscape()">delete</span>
          </div>
        </div>
      </ng-container>
      <div class="favorite-filter-option" *ngIf="!favoriteFiltersForObjectTypes.length">
        <span class="no-filters-text">{{T.common.no_saved_filters | translate}}</span>
      </div>
    </div>
    <div class="favorite-filters-footer">
      <app-button-rectangle
      (clicked)="onFavoriteFiltersApply(); dropdownRef.onEscape()"
      [buttonTheme]="'white'"
      [disabled]="!favoriteFiltersForObjectTypes?.length"
      [text]="T.common.apply | translate"
      >
      </app-button-rectangle>
    </div>
  </div>
</ng-template>
<ng-template #quickFilterContent let-filterSelector="filterSelector" let-dropdownRef="dropdownRef">
  <div class="filter-container">
    <div class="filter-body">
        <app-filters-list-entries
        [entries]="getAllowedFiltersForFilterType(filterSelector.filterType)"
        [selectedEntries]="getAppliedFiltersByType(filterSelector)"
        [useCheckbox]="true"
        (click)="$event.stopPropagation(); $event.stopImmediatePropagation()"
        (entrySelected)="onFilterSelected($event, filterSelector)"
        ></app-filters-list-entries>
      </div>
      <div class="exclude-filters-container">
        <app-filters-exclude-selected
          [checkState]="excludeState"
          [disabled]="!localSelectedFilters.length"
          (excluded)="onExclude($event)">
        </app-filters-exclude-selected>
      </div>
    <div class="filter-footer">
      <app-button-transparent
      style="margin-right: auto;"
      [text]="T.common.clear_all | translate"
      [size]="'small'"
      (clicked)="clearAllFiltersForFilterType(filterSelector); dropdownRef.onEscape()"
      ></app-button-transparent>
      <app-button-rectangle
      (clicked)="onApplyLocalFilters(); dropdownRef.onEscape()"
      [text]="'Apply'"
      [buttonTheme]="'white'"
      >
      </app-button-rectangle>
    </div>
  </div>
</ng-template>
<ng-template #quickDateFilterContent let-filterSelector="filterSelector" let-dropdownRef="dropdownRef">
  <div class="dates-filter-container">
    <div class="filter-body">
      <div class="date-filter-options-container">
        <div class="date-option"
        [ngClass]="{'selected': isNoneDateFilterSelected(filterSelector)}"
        (click)="removeDateFilter(filterSelector)"
        >
          {{T.common.none | translate}}
        </div>
        <div class="date-option"
        *ngFor="let relativeOption of filterSelector.dateRangeOptions"
        (click)="onRelativeDateSelected(filterSelector, relativeOption)"
        [ngClass]="{'selected': isDateFilterSelected(filterSelector, relativeOption)}"
        >
          {{sanitizeFilterDateOption(relativeOption)}}
        </div>
        <div class="date-option"
        [ngClass]="{'selected': isFixedDateRangeSelected(filterSelector)}"
        ngRangeDatepicker
        [startDate]="rangeFromDate(filterSelector)"
        [endDate]="rangeToDate(filterSelector)"
        [timePicker]="showTimePicker(filterSelector)"
        (datesChanged)="onDateRangeChanged($event, filterSelector)"
        >
          {{T.common.date_range | translate}}
        </div>
        <div
        *ngIf="isFixedDateRangeSelected(filterSelector)"
        class="range-date-boxes"
        ngRangeDatepicker
        [startDate]="rangeFromDate(filterSelector)"
        [endDate]="rangeToDate(filterSelector)"
        [timePicker]="showTimePicker(filterSelector)"
        (datesChanged)="onDateRangeChanged($event, filterSelector)"
        >
          <div class="date-box">
            {{getRangeDateStartValue(filterSelector)}}
          </div>
          <div class="date-box">
            {{getRangeDateEndValue(filterSelector)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #leftSideTemplate>
  <ng-content select="[left-side]"></ng-content>
</ng-template>

<ng-template #rightSideTemplate>
  <ng-content select="[right-side]"></ng-content>
</ng-template>
