<div #wrapper class="wrapper">
  <div class="beads-wrapper" [class.beads-wrapper-with-bubbles]="showBubbles">
    <div class="bead" [class.active]="bead.active" *ngFor="let bead of beads" (click)="onBeadClicked(bead)">
      <div class="left-thread"></div>
      <div class="bead-icon-wrapper" *ngIf="showBubbles">
        <i class="{{ getBeadClass(bead) }}" *ngIf="bead.icon">{{ bead.icon }}</i>
        <i class="txt-white" *ngIf="bead.number">{{ bead.number }}</i>
        <div class="bead-description-wrapper">
          <div class="bead-description">{{ bead.description }}</div>
        </div>
      </div>
      <div class="right-thread"></div>
    </div>
  </div>
</div>
