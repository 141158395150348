import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivityService } from 'src/app/modules/shared/services/activity.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { TimeZoneService } from 'src/app/modules/shared/services/timeZone.service';
import { ObjectTypes } from '../../../enums/objectTypes';
import { ObjectEventEmitters } from '../../../events/object.events';
import { Account } from '../../../models/account';
import { Employee } from '../../../models/employee';
import { getDatesDifferenceInDays } from '../../../utilities/date.utilities';
import { DatePipe } from '@angular/common';
import { RAGStatuses } from '../../../enums/ragStatuses';
import { CardTypes } from '../../../enums/cardTypes';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { ModuleService } from '../../../services/module.service';
import { HistoryFeedTheme } from '../../../enums/historyFeedTheme';
import { ActivatedRoute, Router } from '@angular/router';
import { NgImagePreviewDirective } from '../../../directives/ngImagePreview.directive';
import { VideoModalComponent } from '../../modals/video/video-modal.component';
import { Configuration } from 'src/app/app.constants';
import { DocumentService } from '../../../services/document.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Constants } from 'src/app/modules/shared/models/constants';
import { FilterTypes } from '../../../enums/filterTypes';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { UpdateTypes } from '../../../enums/updateTypes';
import { UpdateTypeUtilities } from '../../../utilities/updateType.utilities';
import { RichTextEditorComponent } from '../rich-text-editor/rich-text-editor.component';

@Component({
  selector: 'app-history-feed-new',
  templateUrl: './history-feed-new.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['history-feed-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
})
export class HistoryFeedNewComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(RichTextEditorComponent) richTextEditorComponent: RichTextEditorComponent;

  @Input() title: string = this.translateService.instant(T.common.history);
  @Input() description: string = this.translateService.instant(T.common.history_feed_default_description);
  @Input() showHeader: boolean = true;
  @Input() objectId: number;
  @Input() objectType: ObjectTypes;
  @Input() singleItemHistory: boolean = false;
  @Input() backgroundTheme: HistoryFeedTheme = HistoryFeedTheme.WHITE;
  @Input() isModal: boolean = false;
  @Input() canEdit: boolean = true;
  @Input() showInput: boolean = false;
  @Input() skeletonCount: number = 16;
  @Input() hideToggle: boolean = false;
  @Input() commentType: UpdateTypes = undefined;
  @Input() headlineStatusCheckbox: boolean = false;
  /**
   * child Account Id for Hub Account
   */
  @Input() childHubAccountId: number = 0;

  @Output() historyEntriesCount = new EventEmitter<number>();
  @Output() onMarkAsHeadlineStatus: EventEmitter<string> = new EventEmitter();

  @ViewChild(NgImagePreviewDirective) ngImagePreviewDirective: NgImagePreviewDirective;

  public selectedCommentType: UpdateTypes = this.commentType;
  private imageAttribute = 'data-url';
  private documentAttribute = 'data-download';
  private dataDocumentIdAttribute = 'data-download-id';
  private videoIdAttribute = 'data-video-id';
  private videoTitleAttribute = 'data-video-title';
  private externalLinkAttribute = 'data-external-url';

  // Only for Incidents
  incidentsHistoryTabs = ['combined_history', 'incidents_sub_type', 'logs_sub_type'];
  currentlySelectedTab = this.incidentsHistoryTabs[0];

  subscriptions: Subscription[] = [];
  isLoading: boolean = true;
  currentAccount: Account;
  currentEmployee: Employee;

  historyEntries: object;
  historyMultipleEntries: object;
  historyItemDates: string[];
  currentModule: ModuleTypes;
  bsModalRef: BsModalRef;
  documentUrl: string;
  showToggle: boolean = true;
  selectedStatus = { value: 'All History', visualText: this.translateService.instant(T.common.all_history) };
  toggleOptions = [
    { value: 'All History', visualText: this.translateService.instant(T.common.all_history) },
    { value: 'Comments Only', visualText: this.translateService.instant(T.common.comments_only) },
  ];
  public readonly moduleType: ModuleTypes.Incidents;
  public readonly filterType: FilterTypes.Incident;
  public readonly T = T;
  public markAsHeadlineStatus: boolean = false;
  private modalConfig = { backdrop: true, ignoreBackdropClick: true };

  ragStatus: RAGStatuses = RAGStatuses.Red;
  itemType: CardTypes = CardTypes.Project;

  comments: object;
  commentsDates: string[];
  showOnlyComments: boolean = false;
  isMobile: boolean = false;
  private readonly mobileWidth = Constants.xs;

  constructor(
    private route: ActivatedRoute,
    private readonly activityService: ActivityService,
    private readonly authenticaitonService: AuthenticationService,
    public readonly changeDetectorRef: ChangeDetectorRef,
    private readonly cachingService: CachingService,
    private readonly timezoneService: TimeZoneService,
    private readonly objectEventEmitters: ObjectEventEmitters,
    private readonly datePipe: DatePipe,
    private readonly moduleService: ModuleService,
    private readonly configuration: Configuration,
    private readonly documentService: DocumentService,
    private readonly bsModalService: BsModalService,
    private router: Router,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      if (data) {
        if (data.bgTheme) {
          this.backgroundTheme = data.bgTheme as HistoryFeedTheme;
        }
      }
    });

    if (!this.selectedCommentType) {
      this.selectedCommentType = UpdateTypeUtilities.GetCommentTypeByObjectType(this.objectType);
    }

    if (this.router.url.includes('/history') || this.router.url.includes('/dashboard')) {
      this.hideToggle = true;
    }

    this.currentAccount = this.authenticaitonService.getCurrentAccount();
    this.currentEmployee = this.cachingService.GetEmployee(this.currentAccount.id);
    this.currentModule = this.moduleService.currentModule;
    this.checkIsMobile();
    this.initEventEmitters();
    this.loadData();

    this.subscriptions.push(
      this.objectEventEmitters.objectAdded$.subscribe((res) => {
        if (res.globalObjectType === ObjectTypes.IncidentItem) {
          this.loadData();
        }
      })
    );

    this.subscriptions.push(
      this.objectEventEmitters.listLightUpdated$.subscribe((res) => {
        if (res.globalObjectType === ObjectTypes.IncidentItem) {
          this.loadData();
        }
      })
    );

    this.subscriptions.push(
      this.objectEventEmitters.objectUpdated$.subscribe((res) => {
        if (res.globalObjectType === ObjectTypes.IncidentItem) {
          this.loadData();
        }
      })
    );
  }

  ngOnChanges(): void {
    if (this.currentAccount && this.currentEmployee) {
      this.loadData();
    }
  }

  loadData() {
    this.isLoading = true;
    if (this.singleItemHistory) {
      this.subscriptions.push(
        this.activityService.getHistoryEntriesByItemId(this.objectType, this.objectId).subscribe((response) => {
          this.historyItemDates = Object.keys(response);
          this.historyEntries = response;
          this.getHistoryComments(response);
          this.isLoading = false;
          this.changeDetectorRef.markForCheck();

          this.historyEntriesCount.emit(this.calculateActivitiesCount());
        })
      );
    } else {
      this.subscriptions.push(
        this.activityService.getActivitiesForModule(this.currentModule, this.objectType, []).subscribe((response) => {
          const mapByDateObj = response;

          this.historyItemDates = Object.keys(mapByDateObj);
          this.historyMultipleEntries = mapByDateObj;

          this.isLoading = false;
          this.changeDetectorRef.markForCheck();
        })
      );
    }
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  localiseDate(isoDate: string): string {
    const localised = (iso: string) => this.timezoneService.localiseDateISOStringByCustomFormat(iso, 'MM/DD/YYYY HH:mm');
    const date = new Date(isoDate);
    const delta = getDatesDifferenceInDays(new Date(localised(new Date().toISOString())), date);

    if (delta === 0) {
      return this.translateService.instant(T.calendar.today);
    }

    if (delta === 1) {
      return this.translateService.instant(T.calendar.yesterday);
    }

    return this.datePipe.transform(date, 'EEE d MMM yyyy');
  }

  public IsHeaderVisible(index: number): boolean {
    if (index === 0) {
      return true;
    }

    const currentItemDate = this.timezoneService.localiseDateISOStringByCustomFormat(
      this.historyEntries[index].created,
      'DDMMYYYY'
    );
    const previousItemDate = this.timezoneService.localiseDateISOStringByCustomFormat(
      this.historyEntries[index - 1].created,
      'DDMMYYYY'
    );

    return currentItemDate !== previousItemDate;
  }

  public GetHeaderDate(historyEntryDate: string): string {
    const todayDate = this.timezoneService.localiseDateISOString(new Date().toISOString(), false, false);

    if (historyEntryDate === todayDate) {
      return this.translateService.instant(T.calendar.today);
    }

    return historyEntryDate;
  }

  get allIncidentsHistoryId(): string {
    return this.incidentsHistoryTabs[0];
  }

  get incidentsHistoryId(): string {
    return this.incidentsHistoryTabs[1];
  }

  get logsHistoryId(): string {
    return this.incidentsHistoryTabs[2];
  }

  get isControlModule(): boolean {
    return this.moduleService.isIncidentsModule;
  }

  get showIncidentHistoryTabs(): boolean {
    return this.isControlModule && !this.singleItemHistory && this.objectType === ObjectTypes.IncidentItem;
  }

  onTabSelect(tabId: string) {
    this.currentlySelectedTab = tabId;
  }

  get historyIncidentsItemKeys() {
    const dates = [];
    this.historyItemDates.forEach((date) => {
      const objectsEntries = this.historyMultipleEntries[date];
      const objectsEntriesKeys = Object.keys(objectsEntries);
      for (let i = 0; i < objectsEntriesKeys.length; i++) {
        const nextObjectId = objectsEntriesKeys[i];
        if (objectsEntries[nextObjectId].find((item) => item.objectSubType === 1)) {
          dates.push(date);
          break;
        }
      }
    });

    return dates;
  }

  get historyIncidentsEntries() {
    const entries = {};

    const datesArr = this.historyIncidentsItemKeys;
    datesArr.forEach((date) => {
      entries[date] = {};

      const objectEntries = this.historyMultipleEntries[date];
      const objectEntriesKeys = Object.keys(objectEntries);
      objectEntriesKeys.forEach((objectId) => {
        const entriesArr = objectEntries[objectId].filter((item) => item.objectSubType === 1);
        if (entriesArr.length > 0) {
          if (!entries[date].hasOwnProperty(objectId)) {
            entries[date][objectId] = entriesArr;
          }
        }
      });
    });

    return entries;
  }

  get historyLogsItemKeys() {
    const dates = [];
    this.historyItemDates.forEach((date) => {
      const objectsEntries = this.historyMultipleEntries[date];
      const objectsEntriesKeys = Object.keys(objectsEntries);
      for (let i = 0; i < objectsEntriesKeys.length; i++) {
        const nextObjectId = objectsEntriesKeys[i];
        if (objectsEntries[nextObjectId].find((item) => item.objectSubType !== 1)) {
          dates.push(date);
          break;
        }
      }
    });

    return dates;
  }

  get historyLogsEntries() {
    const entries = {};

    const datesArr = this.historyLogsItemKeys;
    datesArr.forEach((date) => {
      entries[date] = {};

      const objectEntries = this.historyMultipleEntries[date];
      const objectEntriesKeys = Object.keys(objectEntries);
      objectEntriesKeys.forEach((objectId) => {
        const entriesArr = objectEntries[objectId].filter((item) => item.objectSubType !== 1);
        if (entriesArr.length > 0) {
          if (!entries[date].hasOwnProperty(objectId)) {
            entries[date][objectId] = entriesArr;
          }
        }
      });
    });

    return entries;
  }

  getTitle(objectId, dateEntry) {
    return dateEntry[objectId].value[0].objectTitle;
  }

  getObjectId(index: number, dateEntry) {
    return dateEntry[index].key;
  }

  getObjectRAGStatus(objectId, dateEntry): number {
    const status = dateEntry[objectId].value[0].objectStatus;
    return status;
  }

  getObjectType(objectId, dateEntry): ObjectTypes {
    return dateEntry[objectId].value[0].objectType;
  }

  getObjectSubType(objectId, dateEntry): number {
    return dateEntry[objectId].value[0].objectSubType;
  }

  getHistoryObjectKeysForDate(dateEntry: object): string[] {
    return Object.keys(dateEntry);
  }

  getHistoryEntries(objectId, dateEntry): object[] {
    return dateEntry[objectId].value;
  }

  private initEventEmitters() {
    this.subscriptions.push(
      this.objectEventEmitters.historyAdded$.subscribe((res) => {
        if (this.objectId === res.objectId && this.objectType === res.objectType) {
          const localisedMoment = this.timezoneService.getCurrentMomentInLocalisedTimezone(res.history.created);
          const localisedDate = new Date(localisedMoment.year(), localisedMoment.month(), localisedMoment.date(), localisedMoment.hour(), localisedMoment.minute());
          const dateString = this.getDateAsString(localisedDate);

          if (this.historyEntries[dateString]) {
            this.historyEntries[dateString].unshift(res.history);
          } else {
            const entriesAsArray = Object.entries(this.historyEntries);
            entriesAsArray.unshift([dateString, [res.history]]);

            const arrAsObj = {};

            entriesAsArray.forEach((entry) => {
              arrAsObj[entry[0]] = entry[1];
            });

            this.historyEntries = arrAsObj;
            this.historyItemDates = Object.keys(this.historyEntries);
          }
          this.changeDetectorRef.markForCheck();

          if (this.markAsHeadlineStatus) {
            const regexPattern = /<div class="comment-box[^>]*>(.*?)<\/div>/s;
            const match = res.history.phrase.match(regexPattern);
            let commentText = '';
            if (match && match[1]) {
                commentText = match[1].replace(/<[^>]+>/g, '').trim();
            }
            this.onMarkAsHeadlineStatus.emit(commentText);
          }

          this.historyEntriesCount.emit(this.calculateActivitiesCount());
        }
      })
    );
  }

  private getDateAsString(date: Date): string {
    const day = date.getDate().toString();
    const month = (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();
    return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
  }

  private calculateActivitiesCount(): number {
    let count = 0;
    this.historyItemDates.forEach((key) => (count += this.historyEntries[key].length));

    return count;
  }

  private unsubscribe() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  handleClick(event: MouseEvent) {
    event.stopPropagation();
    const target = event.target as HTMLElement;

    const dataUrlAttributeValue = target.getAttribute(this.imageAttribute);
    const dataDownloadAttributeValue = target.getAttribute(this.documentAttribute);
    const dataDownloadIdAttributeValue = target.getAttribute(this.dataDocumentIdAttribute);
    const dataExternalUrlAttributeValue = target.getAttribute(this.externalLinkAttribute);
    const dataVideoIdAttributeValue = target.getAttribute(this.videoIdAttribute);

    if (this.ngImagePreviewDirective) {
      this.ngImagePreviewDirective.ngImagePreviewInstance.hide();
    }

    if (dataUrlAttributeValue) {
      this.documentUrl = this.configuration.buildDocumentUrl(dataUrlAttributeValue);

      this.ngImagePreviewDirective.image = this.documentUrl;
      this.ngImagePreviewDirective.updateInstance();
      this.ngImagePreviewDirective.ngImagePreviewInstance.show();
    } else if (dataDownloadAttributeValue) {
      this.documentService.downloadDocument(Number(dataDownloadIdAttributeValue));
    } else if (dataExternalUrlAttributeValue) {
      this.openInNewTab(dataExternalUrlAttributeValue);
    } else if (dataVideoIdAttributeValue) {
      const dataVideoTitleAttributeValue = target.getAttribute(this.videoTitleAttribute);

      const initialState = {
        fileName: dataVideoTitleAttributeValue,
        title: dataVideoTitleAttributeValue,
        id: +dataVideoIdAttributeValue,
      };

      const modalParams = Object.assign({}, this.modalConfig, { initialState });

      this.bsModalRef = this.bsModalService.show(VideoModalComponent, modalParams);
    }
  }

  private openInNewTab(url: string) {
    const anchorElement = document.createElement('a');

    anchorElement.href = url;
    anchorElement.target = '_blank';
    document.body.appendChild(anchorElement);
    anchorElement.click();
    anchorElement.remove();
  }

  getHistoryComments(historyEntries) {
    const historyComments = (obj, value) =>
      Object.entries(obj).reduce((acc, [key, val]) => {
        acc[key] = Array.isArray(val) ? val.filter((x) => x.phrase.includes('comment-box')) : val;
        return acc;
      }, {});

    const commentsObject = Object.entries(historyComments(historyEntries, 'comment-box')).filter(
      (data) => !(!Array.isArray(data[1]) || data[1].length === 0)
    );
    this.comments = Object.assign({}, ...commentsObject.map((x) => ({ [x[0]]: x[1] })));
    this.commentsDates = Object.keys(this.comments);
  }

  toggleStateChange(currentState: { value: string; visualText: string }) {
    this.selectedStatus = currentState;
    if (this.selectedStatus.value !== 'All History') {
      this.showOnlyComments = true;
    } else {
      this.showOnlyComments = false;
    }
  }

  checkIsMobile(): void {
    window.innerWidth <= this.mobileWidth ? (this.isMobile = true) : (this.isMobile = false);
  }

  public toggleMarkAsHeadlineStatus() {
    this.markAsHeadlineStatus = !this.markAsHeadlineStatus;
  }

  public focusRichTextEditor() {
    this.richTextEditorComponent.focusEditor();
  }
}
