<div class="radio-button">
  <input #input
    type="radio"
    [name]="name ? name : ''"
    [checked]="checked"
    [disabled]="disabled"
  />

  <div class="radio-circle"></div>
  <div class="radio-check"></div>
</div>
<div *ngIf="text" class="radio-button-text">{{ text }}</div>
