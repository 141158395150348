<div class="wrapper">
  <div class="widget-wrapper">
    <div class="widget-wrapper-top">
      <div class="widget-wrapper-top-left">
        <div class="widget-wrapper-title">
          <span>{{ T.common.weather_forecast | translate }}</span>
        </div>
      </div>
      <div class="widget-wrapper-top-right">
        <div
          style="margin-right: 12px"
          class="dropdown-view-button"
          [closeUponSelection]="true"
          ngDropdown
          [templateRef]="changeViewItemsTemplate"
        >
          <i class="material-icons pb-1">arrow_drop_down</i>
          <div class="change-view-title">
            {{ T.weather.weather_at | translate }}
            {{ weatherLocationSelected ? weatherLocationSelected.locationDetails : 'n/a' }}
          </div>
        </div>
        <app-button-icon-square
          icon="more_horiz"
          [closeUponSelection]="true"
          ngDropdown
          [templateRef]="moreButtonTemplate"
        ></app-button-icon-square>
      </div>
    </div>
    <div class="widget-wrapper-main">
      <div #chartContainer class="chart-container-wrapper">
        <div resized (resizable)="onResized($event)" class="widget-card-body">
          <ng-container *ngIf="!loading">
            <div *ngIf="darkSkyWeatherResponse === null">
              {{ T.common.no_data_available | translate }}
            </div>

            <div *ngIf="!mapView && darkSkyWeatherResponse !== null" #forecastContainer id="today-forecast-container">
              <div class="top">
                <div class="top-content">
                  <div class="daily-summery">
                    <div class="image-data-wrapper">
                      <div class="current-status-image">
                        <img [src]="currentWeatherIcon" alt="" />
                      </div>
                      <div class="current-status-data">
                        <div class="current-status-title">
                          <span>{{ curentTemperatureFormatted }}</span> {{ currentWeatherStatus }}
                        </div>
                        <div class="current-status-details">
                          <div class="forecast-unit-wrappper">
                            <span class="title">{{ T.weather.wind | translate }}:</span>
                            <span class="value">{{ currentWindSpeed }}m/s</span>
                          </div>
                          <div>
                            <span>{{ precipationType }}:</span>{{ precipProbability }}%
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="sub-title">
                      <span> {{ dailySummery }}</span>
                    </div>
                  </div>
                  <div class="next-days-summery">
                    <div *ngFor="let item of dailyData; let index = index" class="day-forecast-wrapper">
                      <div class="min-max-temp">{{ item.minTemperature }}/{{ item.maxTemperature }}</div>
                      <div class="forecast-icon">
                        <img src="{{ getWeatherIcon(item.icon) }}" alt="" />
                      </div>
                      <div class="day">{{ item.dayName }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="daily-extras">
                <div class="high-low-temp">
                  <div class="high-temp">
                    <span class="temp">{{ todayMinTemperatureFormatted }}</span>
                    <span class="spacer">&nbsp;</span>
                    <span class="time"></span>
                  </div>
                  <span class="arrow">→</span>
                  <div class="low-temp">
                    <span class="temp">{{ todayMaxTemperatureFormatted }}</span>
                    <span class="spacer">&nbsp;</span>
                    <span class="time"></span>
                  </div>
                </div>
                <div class="sun-times">
                  <div class="sunrise">
                    <img src="../../../../../../../assets/images/sunrise.png" width="28" height="30" />
                    <span class="spacer">&nbsp;</span>
                    <span class="time">{{ todaySunriseTime }}am</span>
                  </div>
                  <div class="sunset">
                    <img src="../../../../../../../assets/images/sunset.png" width="28" height="30" />
                    <span class="spacer">&nbsp;</span>
                    <span class="time">{{ todaySunsetTime }}pm</span>
                  </div>
                </div>
                <div class="precip-accum">
                  <span class="label">{{ T.weather.rain | translate }}</span>
                  <span class="num">{{ todayPrecipVolume }}</span>
                  <span>&nbsp;</span>
                  <span class="unit">mm</span>
                </div>
              </div>

              <div class="bottom">
                <div class="timeline">
                  <div class="stripes">
                    <span
                      #stripesSpans
                      *ngFor="let item of stripesData"
                      [className]="'stripe ' + item.cssClass"
                      [ngStyle]="{ width: item.procent + '%' }"
                    >
                      <span style="white-space: nowrap">{{ item.summery }}</span>
                    </span>
                    <div
                      class="redline"
                      style="
                        position: absolute;
                        left: 3%;
                        width: 1px;
                        height: 100%;
                        background: rgba(255, 0, 0, 0.5) none repeat scroll 0% 0%;
                      "
                    ></div>
                  </div>
                  <div class="hour-data-container">
                    <div *ngFor="let item of hourlyData; let index = index" class="hour-data">
                      <div class="hour-tick"><span class="even first"></span></div>
                      <div *ngIf="index % 2 === 0" class="hour">{{ item.formatedTime }}</div>
                      <div *ngIf="index % 2 === 0" class="temp">{{ item.temperature }}</div>

                      <div *ngIf="index % 2 !== 0" class="hour"></div>
                      <div *ngIf="index % 2 !== 0" class="temp"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="mapView" #map id="map-container"></div>
          </ng-container>

          <ng-container *ngIf="loading">
            <app-loading [displayLogo]="false">
              <p>{{ loadingMessage }}</p>
            </app-loading>
          </ng-container>

          <div class="footer">
            <div class="left-side" style="float: left">
              <button (click)="switchView()" [class.active]="!mapView" class="link-button">
                {{ T.weather.forecast | translate }}
              </button>
            </div>
            <div class="right-side" style="float: right">
              <button
                #dropdownButtonDarkSky
                type="button"
                style="height: 25px; font-size: 10; font-weight: bold; display: none"
                class="btn filter-button action-btn"
              >
                {{ T.common.show_more | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #changeViewItemsTemplate>
  <div class="dropdown-options-container">
    <div
      *ngFor="let w of weatherLocations"
      class="dropdown-option"
      [ngClass]="{ selected: weatherLocationSelected.locationDetails === w.locationDetails }"
      (click)="onWeatherLocationSelected(w)"
    >
      {{ w.locationDetails }}
    </div>
  </div>
</ng-template>

<ng-template #moreButtonTemplate>
  <div class="dropdown-options-container">
    <div (click)="configureWidget()" class="dropdown-option separator">{{ T.common.configure_widget | translate }}</div>
  </div>
</ng-template>

<!--
  <div class="widget-card">
  <div class="widget-card-title">
    <div
      class="location"
      #dropdownWeatherlocations
      [class.reset-top-margin]="mapView"
      [app-popup]="{
        templateRef: weatherLocationTemplate,
        position: 'bottom',
        arrowPosition: ['top', 'center'],
        paddingless: true
      }"
    >
      {{ weatherLocationSelected ? weatherLocationSelected.locationDetails : 'n/a' }}
    </div>

    <span class="label"
      >{{ T.weather.weather_at | translate }}
      {{ weatherLocationSelected ? weatherLocationSelected.locationDetails : 'n/a' }}</span
    >
    <div class="options">
      <a *ngIf="!isMobile()" class="drag-handler">
        <i class="material-icons"> drag_handle </i>
      </a>
      <a *ngIf="!isMobile()" class="more-options">
        <i
          class="material-icons"
          (click)="$event.stopPropagation()"
          [closeUponSelection]="true"
          ngDropdown
          [templateRef]="moreButtonTemplate"
          >more_horiz</i
        >
        <ng-template #moreButtonTemplate>
          <div class="dropdown-options-container">
            <div
              *ngFor="let w of weatherLocations"
              (click)="onWeatherLocationSelected(w)"
              class="dropdown-option"
              [ngClass]="{ selected: weatherLocationSelected.locationDetails === w.locationDetails }"
            >
              {{ w.locationDetails }}
            </div>
          </div>
        </ng-template>
      </a>
    </div>
  </div>

  <div resized (resizable)="onResized($event)" class="widget-card-body">
    <ng-template #weatherLocationTemplate>
      <app-dropdown-text-item
        *ngFor="let w of weatherLocations"
        [dropdownItem]="w"
        [bindProperty]="'locationDetails'"
        [selected]="weatherLocationSelected.locationDetails === w.locationDetails"
        (dropdownItemSelected)="onWeatherLocationSelected($event)"
      >
      </app-dropdown-text-item>
    </ng-template>

    <ng-container *ngIf="!loading">
      <div *ngIf="darkSkyWeatherResponse == null">
        {{ T.common.no_data_available | translate }}
      </div>

      <div *ngIf="!mapView && darkSkyWeatherResponse != null" #forecastContainer id="today-forecast-container">
        <div class="top">
          <div class="top-content">
            <div class="daily-summery">
              <div class="image-data-wrapper">
                <div class="current-status-image">
                  <img [src]="currentWeatherIcon" alt="" />
                </div>
                <div class="current-status-data">
                  <div class="current-status-title">
                    <span>{{ curentTemperatureFormatted }}</span> {{ currentWeatherStatus }}
                  </div>
                  <div class="current-status-details">
                    <div class="forecast-unit-wrappper">
                      <span class="title">{{ T.weather.wind | translate }}:</span>
                      <span class="value">{{ currentWindSpeed }}m/s</span>
                    </div>
                    <div>
                      <span>{{ precipationType }}:</span>{{ precipProbability }}%
                    </div>
                  </div>
                </div>
              </div>
              <div class="sub-title">
                <span> {{ dailySummery }}</span>
              </div>
            </div>
            <div class="next-days-summery">
              <div *ngFor="let item of dailyData; let index = index" class="day-forecast-wrapper">
                <div class="min-max-temp">{{ item.minTemperature }}/{{ item.maxTemperature }}</div>
                <div class="forecast-icon">
                  <img src="{{ getWeatherIcon(item.icon) }}" alt="" />
                </div>
                <div class="day">{{ item.dayName }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="daily-extras">
          <div class="high-low-temp">
            <div class="high-temp">
              <span class="temp">{{ todayMinTemperatureFormatted }}</span>
              <span class="spacer">&nbsp;</span>
              <span class="time"></span>
            </div>
            <span class="arrow">→</span>
            <div class="low-temp">
              <span class="temp">{{ todayMaxTemperatureFormatted }}</span>
              <span class="spacer">&nbsp;</span>
              <span class="time"></span>
            </div>
          </div>
          <div class="sun-times">
            <div class="sunrise">
              <img src="../../../../../../../assets/images/sunrise.png" width="28" height="30" />
              <span class="spacer">&nbsp;</span>
              <span class="time">{{ todaySunriseTime }}am</span>
            </div>
            <div class="sunset">
              <img src="../../../../../../../assets/images/sunset.png" width="28" height="30" />
              <span class="spacer">&nbsp;</span>
              <span class="time">{{ todaySunsetTime }}pm</span>
            </div>
          </div>
          <div class="precip-accum">
            <span class="label">{{ T.weather.rain | translate }}</span>
            <span class="num">{{ todayPrecipVolume }}</span>
            <span>&nbsp;</span>
            <span class="unit">mm</span>
          </div>
        </div>

        <div class="bottom">
          <div class="timeline">
            <div class="stripes">
              <span
                #stripesSpans
                *ngFor="let item of stripesData"
                [className]="'stripe ' + item.cssClass"
                [ngStyle]="{ width: item.procent + '%' }"
              >
                <span style="white-space: nowrap">{{ item.summery }}</span>
              </span>
              <div
                class="redline"
                style="
                  position: absolute;
                  left: 3%;
                  width: 1px;
                  height: 100%;
                  background: rgba(255, 0, 0, 0.5) none repeat scroll 0% 0%;
                "
              ></div>
            </div>
            <div class="hour-data-container">
              <div *ngFor="let item of hourlyData; let index = index" class="hour-data">
                <div class="hour-tick"><span class="even first"></span></div>
                <div *ngIf="index % 2 === 0" class="hour">{{ item.formatedTime }}</div>
                <div *ngIf="index % 2 === 0" class="temp">{{ item.temperature }}</div>

                <div *ngIf="index % 2 !== 0" class="hour"></div>
                <div *ngIf="index % 2 !== 0" class="temp"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="mapView" #map id="map-container"></div>
    </ng-container>

    <div class="footer">
      <div class="left-side" style="float: left">
        <button (click)="switchView()" [class.active]="!mapView" class="link-button">
          {{ T.weather.forecast | translate }}
        </button>
      </div>
      <div class="right-side" style="float: right">
        <button
          #dropdownButtonDarkSky
          type="button"
          style="height: 25px; font-size: 10; font-weight: bold; display: none"
          class="btn filter-button action-btn"
        >
          {{ T.common.show_more | translate }}
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="loading">
    <app-loading [displayLogo]="false">
      <p>{{ loadingMessage }}</p>
    </app-loading>
  </ng-container>
</div>
-->
