<app-modal>
  <app-modal-header [title]="T.control.add_incident.one | translate" [showDelete]="false" (closed)="confirmClose()">
  </app-modal-header>
  <app-threaded-beads
    style="padding: 0 24px"
    [beads]="beads"
    *ngIf="currentStep < 6 && !loading"
    (beadClicked)="onBeadClicked($event)"
  >
  </app-threaded-beads>
  <app-modal-body>
    <!-- DETAILS -->
    <ng-container *ngIf="currentStep === 1 && !loading">
      <div class="row col-row-space custom-gutters" [formGroup]="incidentForm">
        <div class="col-12">
          <app-severity-selector
            [currentlySelectedSeverityCheckpoint]="severityCheckpoint"
            (checkpointSelected)="onSeverityCheckpointSelected($event)"
            (scaleGuideToggled)="changeBeadsReference()"
          >
          </app-severity-selector>
        </div>
        <div class="col-12 qa-incident-category">
          <app-filter-dropdown-new
            [singleSelect]="true"
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.Incident_Category"
            [appliedFilters]="filters"
            [typeahead]="true"
            [currentlySelectedSettingValue]="selectedCategory?.id"
            [label]="T.common.add_item | translate: { item: T.defaultLocalizations.incident_category.many | translate }"
            (onFilterUpdate)="handleFilterChange($event, filterTypes.Incident_Category)"
          >
          </app-filter-dropdown-new>
        </div>
        <div class="col-12 qa-incident-title">
          <app-text-field
            [autofocus]="true"
            [formGroup]="incidentForm"
            formControlName="title"
            [placeholder]="(T.defaultLocalizations.title.one | translate) + '*'"
            [required]="true"
            [useReactiveMessages]="true"
          >
          </app-text-field>
        </div>
        <div class="col-12 qa-incident-description">
          <app-text-field
            [formGroup]="incidentForm"
            formControlName="description"
            [placeholder]="T.defaultLocalizations.description.one | translate"
            [required]="false"
            [useReactiveMessages]="true"
          >
          </app-text-field>
        </div>
        <div class="col-12">
          <app-text-field
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="incident.headlineStatus"
            name="headlineStatus"
            [placeholder]="T.defaultLocalizations.headline_status.one | translate"
            [maxLength]="255"
            [minLength]="3"
            [required]="false"
          >
          </app-text-field>
        </div>
        <ng-container *ngIf="showDueDateAndStartDate">
          <div class="col-6 qa-incident-sDate">
            <app-datepicker
              [placeholder]="(T.common.start_date.one | translate) + '*'"
              [required]="true"
              [date]="start"
              (dateChanged)="onStartDateChanged($event)"
            >
            </app-datepicker>
          </div>
          <div class="col-6 qa-incident-dDate">
            <app-datepicker
              [placeholder]="(T.defaultLocalizations.due_date.one | translate) + '*'"
              [required]="false"
              [date]="due"
              (dateChanged)="onEndDateChanged($event)"
            >
            </app-datepicker>
          </div>
        </ng-container>
        <div class="col-12 qa-incident-endTime">
          <app-optional-date-time
            [firstDateLabel]="T.calendar.start | translate"
            [secondDateLabel]="T.calendar.end | translate"
            [firstDateString]="incident.startTime"
            [secondDateString]="incident.endTime"
            [showPickers]="showPickers"
            [editMode]="true"
            (onFirstDateChanged)="onStartTimeChanged($event)"
            (onSecondDateChanged)="onEndTimeChanged($event)"
            (onShowPickersChanged)="onShowPickersChanged($event)"
          ></app-optional-date-time>
        </div>
      </div>
    </ng-container>

    <!-- LOCATION -->
    <ng-container *ngIf="currentStep === 2 && !loading">
      <div class="row col-row-space custom-gutters">
        <div class="col-12 qa-incident-zone">
          <app-filter-dropdown-new
            [label]="T.common.add_item | translate: { item: localisedZones }"
            [filterType]="filterTypes.Zone"
            [filterByModuleObjectType]="false"
            [appliedFilters]="filters"
            [typeahead]="true"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
          <span class="subtext mt-1 mt-sm-0">{{
            T.common.which_items_are_affected | translate: { items: localisedZones }
          }}</span>
        </div>
      </div>
      <div class="row col-row-space custom-gutters">
        <div class="col-12 qa-incident-area">
          <app-filter-dropdown-new
            [label]="T.common.add_item | translate: { item: localisedAreas }"
            [filterType]="filterTypes.Area"
            [filterByModuleObjectType]="false"
            [appliedFilters]="filters"
            [typeahead]="true"
            [usePredefinedFilters]="true"
            [predefinedFilters]="applicableAreaFilters"
            [relatedObjectId]="selectedZone?.filterValue"
            [disabled]="applicableAreaFilters.length === 0"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
          <span class="subtext mt-1 mt-sm-0">{{
            T.common.which_items_are_affected | translate: { items: localisedAreas }
          }}</span>
        </div>
      </div>
      <div class="row two">
        <div class="col-md-12 marginless-search-dropdown">
          <app-incident-map
            [defaultZoom]="15"
            [lat]="incident.latitude"
            [lng]="incident.longitude"
            [selectedZones]="mapZones"
            [selectedAreas]="mapAreas"
            [showIncidentZonesUponLoad]="true"
            [markerColor]="severityColor"
            [markerType]="markerType"
            [editMode]="true"
            (onLocationUpdated)="onIncidentLocationUpdated($event)"
          >
          </app-incident-map>
        </div>
      </div>
    </ng-container>

    <!-- UPLOADS -->
    <ng-container *ngIf="currentStep === 3 && !loading">
      <div class="row">
        <div class="col-12">
          <app-document-upload
            [globalObjectId]="0"
            [globalObjectType]="objectType"
            [uploadViewModels]="uploadDocumentViewModels"
            [handleUpdatesInternally]="false"
            (uploadViewModelsChanged)="updateUploadViewModels($event)"
          ></app-document-upload>
        </div>
      </div>
    </ng-container>

    <!-- ASSIGN -->
    <ng-container *ngIf="currentStep === 4 && !loading">
      <div class="row col-row-space custom-gutters">
        <div class="col-12 qa-incident-department">
          <app-filter-dropdown-new
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.Department"
            [appliedFilters]="filters"
            [typeahead]="true"
            [label]="T.common.add_item | translate: { item: localisedDepartments }"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
          <span class="subtext mt-1 mt-sm-0">{{
            T.common.which_items_are_involved | translate: { items: localisedDepartments }
          }}</span>
        </div>
      </div>
      <div class="row col-row-space custom-gutters">
        <div class="col-12 qa-incident-owner">
          <app-filter-dropdown-new
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.Owner"
            [appliedFilters]="filters"
            [typeahead]="true"
            [label]="T.common.add_owner.many | translate"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
          <span class="subtext mt-1 mt-sm-0">{{
            T.common.which_individuals_are_involved_and_should_be_notified | translate
          }}</span>
        </div>
      </div>
      <div class="row col-row-space custom-gutters">
        <div class="col-12 qa-incident-owner">
          <app-filter-dropdown-new
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.User_Group"
            [appliedFilters]="filters"
            [typeahead]="true"
            [label]="T.common.add_employee_group.many | translate"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
          <span class="subtext mt-1 mt-sm-0">{{
            T.common.which_groups_of_localisedEmployees_should_be_notified
              | translate: { localisedEmployees: objectTypes.Employee | localiseObjectType: true }
          }}</span>
        </div>
      </div>
      <div class="row col-row-space custom-gutters">
        <div class="col-12">
          <app-text-field
            [(ngModel)]="incident.reportedBy"
            name="reportedBy"
            [placeholder]="T.defaultLocalizations.reported_by.one | translate"
            [maxLength]="120"
            [minLength]="3"
          >
          </app-text-field>
        </div>
      </div>
      <ng-container *ngIf="showPrivacyStatus">
        <div class="row">
          <div class="col-12 qa-incident-privacyStatus">
            <div class="checkbox-wrapper m-t-20">
              <app-checkbox
                [isChecked]="privacyStatus.key !== privacyStatusesEnum.Open"
                (checked)="onMakeConfidentialChecked($event)"
              >
              </app-checkbox>
              <span> {{ T.common.make_confidential | translate }} </span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="showPrivacyStatus && showConfidentialStatuses">
          <div class="row">
            <div class="col-12 qa-incident-rpivacyStatus">
              <app-simple-dropdown
                #searchDropdown
                [objects]="filteredStatuses"
                [bindProperty]="'value'"
                [label]="T.defaultLocalizations.privacy_status.one | translate"
                [currentlySelectedObject]="privacyStatus"
                (objectSelected)="onPrivacyStatusSelected($event)"
              >
              </app-simple-dropdown>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="row col-row-space custom-gutters">
        <div class="col-12 qa-incident-tags">
          <app-add-modal-tag-groups
            [filters]="filters"
            [layoutClasses]="'col-12'"
            [tagGroupObjectType]="objectType"
            (onFiltersUpdated)="handleFilterChange($event)"
          ></app-add-modal-tag-groups>
        </div>
      </div>
    </ng-container>

    <!-- SUMMARY -->
    <ng-container *ngIf="currentStep === 5 && !loading">
      <div class="section section-buttons">
        <div class="row">
          <div class="col-6 qa-incident-btn-draft">
            <app-button-rectangle
              [fitToContainer]="true"
              [text]="T.common.create_as_draft | translate"
              [size]="'large'"
              (click)="onConfirmAsDraft()"
              >{{ T.common.create_as_draft | translate }}</app-button-rectangle
            >
          </div>
          <div class="col-6 qa-incident-btn-confirm">
            <app-button-rectangle
              [fitToContainer]="true"
              [text]="T.common.confirm | translate"
              [buttonTheme]="'white'"
              [size]="'large'"
              (click)="onConfirm()"
              >{{ T.common.confirm | translate }}</app-button-rectangle
            >
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section-header">
          <div class="section-header-title">{{ T.common.details | translate }}</div>
          <div class="section-header-edit" (click)="onEdit(1)">
            <i class="material-icons">edit</i>
            <span>{{ T.common.edit | translate }}</span>
          </div>
        </div>

        <div class="section-body">
          <div class="row">
            <div class="col-4">{{ T.common.severity.one | translate }}:</div>
            <div class="col-8">{{ severityCheckpoint.severity }} - {{ severityCheckpoint.description }}</div>
          </div>
          <div class="row">
            <div class="col-4">{{ T.defaultLocalizations.title.one | translate }}:</div>
            <div class="col-8">{{ incidentForm.controls.title.value }}</div>
          </div>
          <div class="row">
            <div class="col-4">{{ T.defaultLocalizations.description.one | translate }}:</div>
            <div class="col-8">{{ incidentForm.controls.description.value }}</div>
          </div>
          <div class="row">
            <div class="col-4">{{ T.defaultLocalizations.incident_category.many | translate }}:</div>
            <div class="col-8">
              {{ getFilterValuesByFilterType(filterTypes.Incident_Category) }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{ T.defaultLocalizations.tag.many | translate }}:</div>
            <div class="col-8">
              {{ getFilterValuesByFilterType(filterTypes.Tag) }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{ T.common.privacy.one | translate }}:</div>
            <div class="col-8">{{ incidentPrivacyString }}</div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="section-header">
          <div class="section-header-title">{{ T.common.location.one | translate }}</div>
          <div class="section-header-edit" (click)="onEdit(2)">
            <i class="material-icons">edit</i>
            <span>{{ T.common.edit | translate }}</span>
          </div>
        </div>
        <div class="section-body">
          <div class="row">
            <div class="col-4">{{ localisedZones }}:</div>
            <div class="col-8">
              {{ getFilterValuesByFilterType(filterTypes.Zone) }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{ localisedAreas }}:</div>
            <div class="col-8">
              {{ getFilterValuesByFilterType(filterTypes.Area) }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{ T.common.location.many | translate }}:</div>
            <div class="col-8">{{ incident.locationDetails }}</div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="section-header">
          <div class="section-header-title">{{ T.common.uploads | translate }}</div>
          <div class="section-header-edit" (click)="onEdit(3)">
            <i class="material-icons">edit</i>
            <span>{{ T.common.edit | translate }}</span>
          </div>
        </div>
        <div class="section-body">
          <ng-container *ngIf="uploadDocumentViewModels.length > 0">
            <div class="row">
              <div class="col-4"></div>
              <div class="col-8">
                <span *ngFor="let a of uploadDocumentViewModels">{{ a.title }}<br /></span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="section">
        <div class="section-header">
          <div class="section-header-title">{{ T.common.assign | translate }}</div>
          <div class="section-header-edit" (click)="onEdit(4)">
            <i class="material-icons">edit</i>
            <span>{{ T.common.edit | translate }}</span>
          </div>
        </div>
        <div class="section-body">
          <div class="row">
            <div class="col-4">{{ localisedDepartments }}:</div>
            <div class="col-8">
              {{ getFilterValuesByFilterType(filterTypes.Department) }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{ localisedOwners }}:</div>
            <div class="col-8">
              {{ getFilterValuesByFilterType(filterTypes.Owner) }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- INCIDENT ADDED - NEW INCIDENT DETAILS -->
    <ng-container *ngIf="currentStep === 6 && !loading">
      <div class="d-flex modal-final-step">
        <app-button-rectangle
          [fitToContainer]="true"
          class="final-step-button"
          [size]="'large'"
          [buttonTheme]="'primary'"
          [text]="T.control.view_incident_details | translate"
          (click)="onViewIncidentDetails()"
        ></app-button-rectangle>
        <app-button-rectangle
          [fitToContainer]="true"
          class="final-step-button"
          [size]="'large'"
          [buttonTheme]="'white'"
          [text]="T.common.add_another | translate"
          (click)="onAddAnotherIncident()"
        ></app-button-rectangle>
        <app-button-rectangle
          [fitToContainer]="true"
          class="final-step-button"
          [size]="'large'"
          [buttonTheme]="'white'"
          [text]="T.common.close | translate"
          (click)="onClose()"
        ></app-button-rectangle>
      </div>
    </ng-container>

    <ng-container *ngIf="loading">
      <app-loading [displayLogo]="true">
        <p>{{ T.control.adding_incident | translate }}...</p>
      </app-loading>
    </ng-container>
  </app-modal-body>
  <app-modal-footer>
    <div class="incident-modal-footer">
      <app-button-rectangle
        class="d-none d-md-block modal-cancel"
        [size]="'large'"
        *ngIf="currentStep < 6"
        [text]="currentStep === 1 ? (T.common.cancel | translate) : (T.common.back | translate)"
        [buttonTheme]="'white'"
        (clicked)="onCancel()"
      ></app-button-rectangle>
      <app-button-rectangle
        [size]="'large'"
        [loading]="loading"
        class="modal-saveAndExit"
        *ngIf="currentStep < 6"
        [buttonTheme]="'primary'"
        [text]="T.common.save_and_close | translate"
        [disabled]="!validateCurrentStep(1)"
        (clicked)="onSaveAndExit()"
      ></app-button-rectangle>
      <app-button-rectangle
        [size]="'large'"
        class="modal-skip"
        *ngIf="currentStep === 6"
        [buttonTheme]="'white'"
        [text]="T.common.skip | translate"
        [disabled]="!currentStepIsValid"
        (clicked)="onClose()"
      ></app-button-rectangle>
      <app-button-rectangle
        [size]="'large'"
        class="modal-confirm"
        *ngIf="currentStep < 6"
        [buttonTheme]="'primary'"
        [text]="currentStepButton"
        [disabled]="!currentStepIsValid"
        [loading]="loading"
        (clicked)="onConfirm()"
      ></app-button-rectangle>
    </div>
  </app-modal-footer>
</app-modal>
