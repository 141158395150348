import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ThreadedBead } from '../../../models/threadedBead';

@Component({
  selector: 'app-threaded-beads',
  templateUrl: 'threaded-beads.component.html',
  styleUrls: ['./threaded-beads.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreadedBeadsComponent {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef<HTMLElement>;

  @Input() beads: ThreadedBead[] = [];
  @Input() showBubbles: boolean = true;

  @Output() beadClicked: EventEmitter<ThreadedBead> = new EventEmitter();

  getBeadClass(bead: ThreadedBead) {
    return 'material-icons ' + bead.cssClasses;
  }

  onBeadClicked(bead: ThreadedBead) {
    this.beadClicked.emit(bead);
  }
}
