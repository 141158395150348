import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { ObjectTypes } from '../../../enums/objectTypes';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit {
  @Input() title: string;
  @Input() items: any;
  @Input() objectType: ObjectTypes;
  @Input() itemsPerPage = 4;
  @Input() useAutoplay = false;
  @Input() showIndicators = true;

  public currentItems: any[] = [];
  public currentPage: number = 0;
  public totalPages: number = 0;
  public totalPagesArray: number[] = [];
  public animateForward: boolean = false;
  public animateBackward: boolean = false;
  public objectTypes = ObjectTypes;
  public autoplayDelay: number = 3000;
  public autoplayInterval: any;
  public isAutoplay: boolean = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.calculateTotalPages();
    this.updateCurrentItems();
  }

  public calculateTotalPages(): void {
    this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
    this.totalPagesArray = Array.from({ length: this.totalPages }, (_, i) => i);
  }

  public updateCurrentItems(direction?: string): void {
    this.animateForward = false;
    this.animateBackward = false;
    const startIndex = this.currentPage * this.itemsPerPage;
    this.currentItems = this.items.slice(startIndex, startIndex + this.itemsPerPage);
    if (direction) {
      this.triggerAnimation(direction);
    }
  }

  public goToPage(page: number): void {
    this.currentPage = page;
    this.updateCurrentItems();
  }

  public nextPage(): void {
    if (this.currentPage < this.totalPagesArray.length - 1) {
      this.currentPage++;
      this.updateCurrentItems('forward');
    }
  }

  public previousPage(): void {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.updateCurrentItems('backward');
    }
  }

  public triggerAnimation(direction: string): void {
    if (direction === 'forward') {
      this.animateBackward = false;
      this.animateForward = false;
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.animateForward = true;
        this.changeDetectorRef.detectChanges();
      }, 0);
    } else if (direction === 'backward') {
      this.animateForward = false;
      this.animateBackward = false;
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.animateBackward = true;
        this.changeDetectorRef.detectChanges();
      }, 0);
    }
  }

  public startAutoplay(): void {
    if(this.useAutoplay) {
      if (!this.isAutoplay) {
        this.isAutoplay = true;
        this.autoplayInterval = setInterval(() => {
          this.nextPage();
          if (this.currentPage === this.totalPagesArray.length - 1) {
            this.currentPage = 0;
            this.updateCurrentItems();
          }
        }, this.autoplayDelay);
      }
    }
  }

  public stopAutoplay(): void {
    if (this.isAutoplay) {
      clearInterval(this.autoplayInterval);
      this.isAutoplay = false;
    }
  }
}
