import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import { IncidentChannelViewModel } from 'src/app/modules/settings/viewModels/incidentChannelsViewModel';
import { T } from 'src/assets/i18n/translation-keys';
import { EventDetailsViewModel } from '../../../../settings/viewModels/eventDetailsViewModel';
import { FilterSelectorTypes } from '../../../enums/filter/filterSelectorTypes';
import { FilterTypes } from '../../../enums/filterTypes';
import { ObjectTypes } from '../../../enums/objectTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FixedEventFiltersService } from '../../../services/fixed-event-filters.service';
import { FixedIncidentChannelFiltersService } from '../../../services/fixed-incident-channel-filters.service';
import { FixedZoneFiltersService } from '../../../services/fixed-zone-filters.service';
import { LocalisationService } from '../../../services/localisation.service';
import { FixedFilterType } from '../../../types/FixedFilterType';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { ZoneViewModel } from '../../../viewModels/zoneViewModel';

@Component({
  selector: 'app-fixed-filter-lozenges',
  templateUrl: './fixed-filter-lozenges.component.html',
  styleUrls: ['./fixed-filter-lozenges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FixedFilterLozengesComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();
  readonly filterTypes = FilterTypes;

  isFixedZoneActive = false;
  zones: ZoneViewModel[] = [];
  appliedFixedZone: FilterViewModel;
  allowedZoneFilters: FilterViewModel[] = [];
  zoneFilterTypeSelector: FilterTypeSelectorViewModel = new FilterTypeSelectorViewModel();

  isFixedEventActive = false;
  events: EventDetailsViewModel[] = [];
  appliedFixedEvent: FilterViewModel;
  allowedEventFilters: FilterViewModel[] = [];
  eventFilterTypeSelector: FilterTypeSelectorViewModel = new FilterTypeSelectorViewModel();

  isFixedIncidentChannelActive = false;
  channels: IncidentChannelViewModel[] = [];
  appliedIncidentChannel: FilterViewModel;
  allowedIncidentChannelFilters: FilterViewModel[] = [];
  incidentFilterTypeSelector: FilterTypeSelectorViewModel = new FilterTypeSelectorViewModel();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    private readonly fixedEventFiltersService: FixedEventFiltersService,
    private readonly fixedZoneFiltersService: FixedZoneFiltersService,
    private readonly fixedIncidentChannelFiltersService: FixedIncidentChannelFiltersService,
    private readonly translateService: TranslateService
  ) {}
  ngOnInit() {
    this.eventFilterTypeSelector.filterType = FilterTypes.Event;
    this.eventFilterTypeSelector.filterTypeText = this.translateService.instant(T.control.live_localisedEvent, {
      localisedEvent: this.localisationService.localiseObjectType(ObjectTypes.Event),
    });
    this.eventFilterTypeSelector.filterSelectorType = FilterSelectorTypes.Dropdown;
    this.eventFilterTypeSelector.displayForObjectType = ObjectTypes.Global;

    this.zoneFilterTypeSelector.filterType = FilterTypes.Zone;
    this.zoneFilterTypeSelector.filterTypeText = this.localisationService.localiseObjectType(ObjectTypes.Zone);
    this.zoneFilterTypeSelector.filterSelectorType = FilterSelectorTypes.Dropdown;
    this.zoneFilterTypeSelector.displayForObjectType = ObjectTypes.Global;

    this.incidentFilterTypeSelector.filterType = FilterTypes.Incident_Channel;
    this.incidentFilterTypeSelector.filterTypeText = this.translateService.instant(T.defaultLocalizations.incident_channel.one);
    this.incidentFilterTypeSelector.filterSelectorType = FilterSelectorTypes.Dropdown;
    this.incidentFilterTypeSelector.displayForObjectType = ObjectTypes.Global;

    this.subscriptions.add(
      combineLatest([
        this.fixedEventFiltersService.isFilterActive$,
        this.fixedEventFiltersService.fixedFilter$,
        this.fixedEventFiltersService.allowedFilters$,
        this.fixedEventFiltersService.allowedEntities$,
      ]).subscribe(([isFilterActive, fixedFilter, allowedFilters, allowedEvents]) => {
        this.isFixedEventActive = isFilterActive;
        this.appliedFixedEvent = fixedFilter;
        this.allowedEventFilters = allowedFilters;
        this.events = allowedEvents;

        this.changeDetectorRef.markForCheck();
      })
    );

    this.subscriptions.add(
      combineLatest([
        this.fixedZoneFiltersService.isFilterActive$,
        this.fixedZoneFiltersService.fixedFilter$,
        this.fixedZoneFiltersService.allowedFilters$,
        this.fixedZoneFiltersService.allowedEntities$,
      ]).subscribe(([isFilterActive, fixedFilter, allowedFilters, allowedZones]) => {
        this.isFixedZoneActive = isFilterActive;
        this.appliedFixedZone = fixedFilter;
        this.allowedZoneFilters = allowedFilters;
        this.zones = allowedZones;

        this.changeDetectorRef.markForCheck();
      })
    );

    this.subscriptions.add(
      combineLatest([
        this.fixedIncidentChannelFiltersService.isFilterActive$,
        this.fixedIncidentChannelFiltersService.fixedFilter$,
        this.fixedIncidentChannelFiltersService.allowedFilters$,
        this.fixedIncidentChannelFiltersService.allowedEntities$,
      ]).subscribe(([isFilterActive, fixedFilter, allowedFilters, allowedChannels]) => {
        this.isFixedIncidentChannelActive = isFilterActive;
        this.appliedIncidentChannel = fixedFilter;
        this.allowedIncidentChannelFilters = allowedFilters;
        this.channels = allowedChannels;

        this.changeDetectorRef.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get shouldShowFixedLozenges(): boolean {
    return this.isFixedEventActive || this.isFixedZoneActive || this.isFixedIncidentChannelActive;
  }

  onFiltersChanged(filters: FilterViewModel[], filterType: FixedFilterType): void {
    switch (filterType) {
      case FilterTypes.Event: {
        this.fixedEventFiltersService.setFixedFilter(filters[0]);
        break;
      }
      case FilterTypes.Zone: {
        this.fixedZoneFiltersService.setFixedFilter(filters[0]);
        break;
      }
      case FilterTypes.Incident_Channel: {
        this.fixedIncidentChannelFiltersService.setFixedFilter(filters[0]);
        break;
      }
    }
  }
}
