<app-loading *ngIf="isLoading"></app-loading>
<div *ngIf="!isLoading" class="add-modal-container">
  <div class="modal-header-container d-flex justify-content-between align-items-center">
    <app-button-transparent
      size="large"
      class="d-block d-md-none"
      text="Cancel"
      (clicked)="confirmClose()"
    ></app-button-transparent>
    <span class="modal-title">{{ T.common.add_item | translate: { item: getSelectedRiskName } }}</span>
    <span class="material-icons close-icon d-none d-md-block" (click)="confirmClose()">close</span>
    <app-button-transparent
      size="large"
      class="d-block d-md-none"
      [text]="T.common.save | translate"
      (clicked)="handleAction(addModalButtonOptions.Save_Close)"
    ></app-button-transparent>
  </div>
  <div class="validations-erros-container" *ngIf="validationErrors?.length > 0">
    <div class="validation-error" *ngFor="let error of validationErrors">
      <i class="material-icons">error_outline</i>
      {{ error }}
    </div>
  </div>
  <div class="modal-body-container row col-row-space custom-gutters" [formGroup]="form">
    <div class="col-12 d-flex radio-buttons">
      <app-radio-button
        [checked]="this.selectedRiskType === riskTypes.Risk"
        [text]="localiseRiskType(riskTypes.Risk)"
        (checkedChanged)="selectType(riskTypes.Risk)"
      ></app-radio-button>
      <app-radio-button
        [checked]="this.selectedRiskType === riskTypes.Issue"
        [text]="localiseRiskType(riskTypes.Issue)"
        (checkedChanged)="selectType(riskTypes.Issue)"
      ></app-radio-button>
      <app-radio-button
        [checked]="this.selectedRiskType === riskTypes.Opportunity"
        [text]="localiseRiskType(riskTypes.Opportunity)"
        (checkedChanged)="selectType(riskTypes.Opportunity)"
      ></app-radio-button>
    </div>
    <div class="container-with-bottom-element col-md-8 col-12">
      <div class="selection">
        <app-text-field
          [autofocus]="true"
          [displayError]="triggerErrors"
          formControlName="riskTitle"
          [useReactiveMessages]="true"
          [maxLength]="250"
          [disableMargin]="true"
          [required]="true"
          [placeholder]="(T.defaultLocalizations.title.one | translate) + '*'"
          [formGroup]="form"
          [svg]="svg"
        >
        </app-text-field>
      </div>
      <div class="bottom-element d-flex">
        <app-button-transparent
          [app-tooltip]="{
            title: T.defaultLocalizations.title.one | translate,
            message: T.common.risk_add_modal_title_tooltip | translate: [objectTypes.Risk_Impact | localiseObjectType: true]
          }"
          size="small"
          icon="error_outline"
          [text]="T.common.learn_more | translate"
        ></app-button-transparent>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <app-filter-dropdown-new
        [useSearch]="false"
        [filterByModuleObjectType]="false"
        [displayError]="triggerErrors"
        [required]="true"
        [currentlySelectedSettingValue]="1"
        [filterType]="filterTypes.Risk_Status"
        [excludeFilterValues]="exludeRiskStatuses"
        [singleSelect]="true"
        [appliedFilters]="filters"
        [typeahead]="true"
        [label]="(T.common.status.one | translate) + '*'"
        [disableMargin]="true"
        (onFilterUpdate)="handleFilterChange($event)"
      >
      </app-filter-dropdown-new>
    </div>
    <!-- <div class="container-with-bottom-element col-12 d-flex"> -->
    <div [ngClass]="isMobile ? 'col-12' : 'col-3'">
      <div class="selection">
        <app-filter-dropdown-new
          [useSearch]="false"
          [disabled]="selectedRiskType === riskTypes.Issue"
          [displayError]="triggerErrors"
          [disableRequiredText]="true"
          [excludeFilterValues]="excludeDropdownValuesArr"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Risk_Likelihood"
          [singleSelect]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [disableMargin]="true"
          [required]="true"
          [currentlySelectedSettingValue]="likelihoodValue"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="bottom-element d-flex">
        <div class="status-fill-boxes">
          <div
            class="status-fill-single-box"
            *ngFor="let item of [0, 1, 2, 3, 4, 5]; let i = index"
            [class.filled-box]="likelihoodValue >= i + 1"
          ></div>
        </div>
      </div>
    </div>
    <div [ngClass]="isMobile ? 'col-12' : 'col-3'">
      <div class="selection">
        <app-filter-dropdown-new
          [useSearch]="false"
          [displayError]="triggerErrors"
          [disableRequiredText]="true"
          [required]="true"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Risk_Impact"
          [singleSelect]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [disableMargin]="true"
          [currentlySelectedSettingValue]="1"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="bottom-element d-flex">
        <div class="status-fill-boxes">
          <div
            class="status-fill-single-box"
            *ngFor="let item of [0, 1, 2, 3, 4]; let i = index"
            [class.filled-box]="impactValue >= i + 1"
          ></div>
        </div>
      </div>
    </div>

    <div [hidden]="isMobile" class="col-4">
      <div [ngTemplateOutlet]="riskRAGTemplate"></div>
    </div>

    <!-- <div class="col-md-2 col-6 nested-col-right">
                    <div class="d-flex align-items-center details-icon">
                        <app-details-icon [type]="getSelectedRiskName"></app-details-icon>
                    </div>
                </div> -->

    <!-- </div>            -->

    <div [hidden]="!isMobile" class="col-12">
      <div class="d-flex justify-content-center">
        <div [ngTemplateOutlet]="riskRAGTemplate"></div>
      </div>
    </div>

    <div class="col-12">
      <app-hr></app-hr>
    </div>
    <div class="container-with-bottom-element d-flex flex-column col-md-6 col-12">
      <div class="selection">
        <app-filter-dropdown-new
          [filterByModuleObjectType]="false"
          [displayError]="triggerErrors"
          [filterType]="filterTypes.Owner"
          [required]="true"
          [singleSelect]="true"
          [appliedFilters]="filters"
          [disableMargin]="true"
          [typeahead]="true"
          [label]="(T.common.assign_owner.one | translate) + '*'"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="bottom-element d-flex">
        <app-button-transparent
          size="small"
          icon="add"
          [text]="T.common.assign_me | translate"
          (clicked)="setAccountForCurrentOwner()"
        ></app-button-transparent>
      </div>
    </div>
    <div class="container-with-bottom-element d-flex flex-column col-md-6 col-12">
      <div class="selection">
        <app-filter-dropdown-new
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Department"
          [disableMargin]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [label]="T.common.assign_item | translate: { item: localisedDepartment }"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="bottom-element d-flex" *ngIf="employee?.departmentId > 0">
        <app-button-transparent
          size="small"
          icon="add"
          [text]="T.common.assign_my_localisedDepartment | translate: { localisedDepartment: localisedDepartment }"
          (clicked)="setMyDepartment()"
        ></app-button-transparent>
      </div>
    </div>
    <div class="col-md-6 col-12">
      <app-filter-dropdown-new
        [filterByModuleObjectType]="false"
        [filterType]="filterTypes.Project"
        [disableMargin]="true"
        [appliedFilters]="filters"
        [typeahead]="true"
        (onFilterUpdate)="handleFilterChange($event)"
      >
      </app-filter-dropdown-new>
    </div>
    <div class="container-with-bottom-element col-md-6 col-12">
      <div class="selection">
        <app-filter-dropdown-new
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Risk_Impact_Type"
          [disableMargin]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [label]="T.common.impact_type.many | translate: {impact: localisedImpact}"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="bottom-element d-flex">
        <app-button-transparent
          [app-tooltip]="{
            title: T.common.impact_type.one | translate: {impact: localisedImpact},
            message: T.common.risk_add_modal_impact_type_tooltip | translate: [objectTypes.Risk_Impact_Type | localiseObjectType: true]
          }"
          size="small"
          icon="error_outline"
          [text]="T.common.learn_more | translate"
        ></app-button-transparent>
      </div>
    </div>
    <div class="container-with-bottom-element col-md-6 col-12">
      <div class="selection">
        <app-filter-dropdown-new
          [useSearch]="false"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Risk_Strategy"
          [singleSelect]="true"
          [disableMargin]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [label]="T.common.strategy.one | translate"
          (onFilterUpdate)="handleFilterChange($event)"
          [currentlySelectedSettingValue]="3"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="bottom-element d-flex">
        <app-button-transparent
          [app-tooltip]="{
            title: T.common.strategy.one | translate,
            message: T.common.risk_add_modal_strategy_tooltip | translate
          }"
          size="small"
          icon="error_outline"
          [text]="T.common.learn_more | translate"
        ></app-button-transparent>
      </div>
    </div>

    <!-- Hidden Options -->
    <!-- Hidden Options /// It is important to use it with [hidden] instead of *ngIf because some of the filters have inital data that needs to be added-->
    <div class="dummy-holder modal-body-container row col-row-space custom-gutters" [hidden]="hideMoreOptions">
      <div class="col-md-6 col-12 d-flex" *ngIf="!dateReseter">
        <app-datepicker
          [date]="getDueDate"
          (dateChanged)="setDueDate($event)"
          [placeholder]="T.defaultLocalizations.due_date.one | translate"
          [showPlaceholderFirst]="true"
          [disableMargin]="true"
        ></app-datepicker>
      </div>
      <div class="col-md-6 col-12">
        <app-filter-dropdown-new
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Event_Type"
          [appliedFilters]="filters"
          [typeahead]="true"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="col-md-6 col-12">
        <app-filter-dropdown-new
          [useSearch]="false"
          [displayError]="triggerErrors"
          [required]="true"
          [currentlySelectedSettingValue]="'0'"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Risk_Privacy_Status"
          [singleSelect]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [label]="(T.common.privacy.one | translate) + '*'"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="col-md-6 col-12">
        <app-filter-dropdown-new
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Identified_By"
          [singleSelect]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [label]="T.defaultLocalizations.identified_by.one | translate"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="description col-12">
        <app-details-editable-field
          [input]="form.controls.description.value ? form.controls.description.value : ''"
          [header]="T.defaultLocalizations.description.one | translate"
          [type]="editableFieldTypes.RichText"
          [canEdit]="true"
          (onUpdate)="updateDescription($event)"
          (onInputChange)="updateDescription($event)"
          [placeholder]="T.common.add_description | translate"
        ></app-details-editable-field>
      </div>
      <div class="col-12">
        <app-text-field name="headlineStatus" [ngModelOptions]="{ standalone: true }" [(ngModel)]="risk.headlineStatus"
          [placeholder]="T.defaultLocalizations.headline_status.one | translate" [maxLength]="255" [minLength]="3"
          [required]="false">
        </app-text-field>
      </div>
      <app-add-modal-tag-groups
        class="col-12"
        [layoutClasses]="'col-md-6 col-12'"
        [filters]="filters"
        [tagGroupObjectType]="objectTypes.Risk"
        (onFiltersUpdated)="handleFilterChange($event)"
      ></app-add-modal-tag-groups>
    </div>
    <!-- End of Hidden Options -->
    <div class="show-more-btn d-none d-md-block col-12">
      <app-button-bar-rounded
        (clicked)="showHiddenOptions()"
        *ngIf="!isMobile"
        [isOpen]="isHiddenOptionVisible"
        [fitToContainer]="true"
        [text]="T.common.show_more | translate"
        [secondText]="T.common.show_less | translate"
      ></app-button-bar-rounded>
    </div>
    <div class="buttons-row col-12 d-flex justify-content-between align-items-center">
      <app-button-transparent
        class="d-none d-lg-block"
        [app-tooltip]="{
          title: T.common.keyboard_shortcuts | translate,
          position: ['top'],
          templateRef: tooltipTemplate
        }"
        [text]="T.common.keyboard_shortcuts | translate"
        icon="keyboard"
      ></app-button-transparent>
      <div class="action-buttons">
        <app-button-rectangle
          class="button d-none d-md-block"
          [fitToContainer]="true"
          size="large"
          [text]="T.common.close | translate"
          buttonTheme="white"
          (clicked)="confirmClose()"
        ></app-button-rectangle>
        <app-button-dropdown
          class="button"
          [disabled]="form.invalid"
          [fitToContainer]="true"
          [selectedOption]="buttonOptions[0]"
          [options]="buttonOptions"
          (selected)="handleAction($event)"
        ></app-button-dropdown>
      </div>
    </div>
  </div>
  <ng-template #tooltipTemplate>
    <table class="styled-table">
      <tr *ngFor="let keybind of keyboardShortcuts">
        <td>
          <span class="key-shortcut">{{ keybind.key }}</span> -
          <span class="key-description">{{ keybind.description }}</span>
        </td>
      </tr>
    </table>
  </ng-template>

  <ng-template #riskRAGTemplate>
    <div class="d-flex" style="margin-top: 10px">
      <!-- <div style="padding-right: 8px;"><span style="vertical-align: middle;">=</span></div> -->
      <div style="margin-top: -2px; margin-right: 6px">
        <app-details-icon [type]="riskTypes[selectedRiskType]" [backgroundFill]="ragColour"></app-details-icon>
      </div>
      <div>
        <span style="vertical-align: middle">{{ ragDescription }}</span>
      </div>
    </div>
  </ng-template>
</div>
