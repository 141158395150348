import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FilterTypes } from "../../../enums/filterTypes";
import { ChatMessage } from "../../../models/interfaces/chat-message.interface";
import { PrepopulatedTextMessagesService } from "src/app/modules/settings/services/prepopulated-text-msgs.service";
import { Subscription } from "rxjs";
import { PrepopulatedTextMessageInterface } from "../../../interfaces/prepopulated-text-messages/prepopulatedTextMessageInterface";
import { ObjectEventEmitters } from "../../../events/object.events";
import { ObjectTypes } from "../../../enums/objectTypes";
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent implements OnInit, AfterViewChecked {
  @Input() chatMessages: ChatMessage[];
  @Input() loading: boolean = false;
  @Output() onChatMessageSent = new EventEmitter<string>();
  @ViewChild("responseField") responseField: ElementRef<HTMLElement>;

  public readonly T = T;
  public readonly filterTypes = FilterTypes;
  protected message: string = '';
  public subscriptions = new Subscription();
  protected savedTextMessages: PrepopulatedTextMessageInterface[] = [];

  constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly objectEventEmitters: ObjectEventEmitters,
    protected readonly prepopulatedTextMessagesService: PrepopulatedTextMessagesService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.prepopulatedTextMessagesService.getPrepopulatedTextMsgsList().subscribe(res => {
        this.savedTextMessages = res;
        this.savedTextMessages.sort((a, b) => a.title.localeCompare(b.title))
        this.changeDetectorRef.detectChanges();
        }
      )
    )

    this.subscriptions.add(
      this.objectEventEmitters.objectDeleted$.subscribe(res => {
        if (res.globalObjectType === ObjectTypes.Prepopulated_Text_Message) {
          const updatedListOfMsgs = this.savedTextMessages.filter(textMsg => textMsg.id !== res.model.id);
          this.savedTextMessages = [...updatedListOfMsgs];
          this.savedTextMessages.sort((a, b) => a.title.localeCompare(b.title));
          this.changeDetectorRef.detectChanges();
        }
      })
    )

    this.subscriptions.add(
      this.objectEventEmitters.objectAdded$.subscribe((res) => {
        if (res.globalObjectType === ObjectTypes.Prepopulated_Text_Message) {
          this.savedTextMessages = [...this.savedTextMessages, res.model];
          this.savedTextMessages.sort((a, b) => a.title.localeCompare(b.title));
          this.changeDetectorRef.detectChanges();
        }
      })
    );

    this.subscriptions.add(
      this.objectEventEmitters.objectUpdated$.subscribe((res) => {
        if (res.globalObjectType === ObjectTypes.Prepopulated_Text_Message) {
          const updatedListOfMsgs = this.savedTextMessages.filter(textMsg => textMsg.id !== res.model.id);
          this.savedTextMessages = [...updatedListOfMsgs, res.model as PrepopulatedTextMessageInterface];
          this.savedTextMessages.sort((a, b) => a.title.localeCompare(b.title));
          this.changeDetectorRef.detectChanges();
        }
      })
    );


  }

  ngAfterViewChecked(): void {
    this.responseField?.nativeElement?.scrollIntoView({behavior: "instant", block: "end", inline: "nearest"});
  }

  sendResponse(): void {
    if(this.message.length < 1) return;

    this.onChatMessageSent.emit(this.message);
    this.message = '';
  }

  addSavedResponse(textMsg: any):void {
    this.message = textMsg.message;

    this.changeDetectorRef.detectChanges();
  }
}
