export class StringUtilities {
  public static ownerNameInitials(name: string): string {
    const tokens = name.split(' ');
    const firstInitial = tokens[0].substring(0, 1).toUpperCase();
    const secondInitial = tokens.length > 1
      ? tokens[1].substring(0, 1).toUpperCase()
      : '';

    return `${firstInitial + secondInitial}`;
  }
}

export const capitalize = (text: string) => {
  const chars = text.split('');

  chars[0] = chars[0].toUpperCase();

  return chars.join('');
};
