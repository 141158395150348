import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  HostListener,
  ChangeDetectorRef,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { ModuleService } from 'src/app/modules/shared/services/module.service';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/modules/shared/models/constants';
import { NgDropdownDirective } from 'src/app/modules/shared/directives/ngDropdown.directive';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { SortingService } from 'src/app/modules/shared/services/sorting.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CloseIncidentModal } from 'src/app/modules/incidents/components/common/modals/close-incident/close-incident-modal.component';
import { IncidentStatuses } from 'src/app/modules/incidents/enums/incidentStatuses';
import { IncidentStatusUpdateViewModel } from 'src/app/modules/shared/models/incidentStatusUpdate';
import { FilterActionTypes } from 'src/app/modules/shared/enums/filter/filterActionTypes.enum';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { MilestoneTypes } from 'src/app/modules/shared/enums/milestoneTypes';
import { IncidentItemViewModel } from 'src/app/modules/incidents/viewModels/incidentItemViewModel';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';

@Component({
  selector: 'app-details-dropdown',
  templateUrl: './details-dropdown.component.html',
  styleUrls: ['./details-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsDropdownComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(NgDropdownDirective, { static: false }) ngDropdownDirective: NgDropdownDirective;
  @Input() filterType: FilterTypes;
  @Input() appliedFilters: FilterViewModel[];
  @Input() label: string;
  @Input() displayLabel = true;
  @Input() currentlySelectedFilterText: string;
  @Input() statusBars = false;
  @Input() isStatus = false;
  @Input() canEdit = true;
  @Input() excludeFilterValues: [];
  @Input() canEditMessage: string = this.translateService.instant(T.common.you_dont_have_permission_to_edit);
  @Input() required: boolean = false;
  @Input() currentDetailsItem: any;
  @Input() showDropdownArrow: boolean = true;
  @Input() arrowPosition: 'left' | 'right' = 'right';
  /**
   * Wheather to use simple update or not. If true, the component will emit only the updated filters.
   */
  @Input() useLightUpdate: boolean = false;
  /**
   * If true, the component will emit the click event to the parent component instead of oppening the dropdown.
   * The purpose of this is to allow the parent component to handle the click event and open a modal or something else.
   */
  @Input() handleClickExternally: boolean = false;

  // Account hub Specific Input for selecting filters for proper child or hub account
  @Input() accountId: number = 0;

  @Output() onFiltersUpdated = new EventEmitter<FilterViewModel[]>();
  @Output() onIncidentStatusFilterUpdated = new EventEmitter<IncidentStatusUpdateViewModel>();
  /**
   * This event is emitted when the user clicks on the dropdown but "handleClickExternally" is set to true.
   */
  @Output() externalClickHandle = new EventEmitter<MouseEvent>();

  public isDropdownOpen = false;
  public settings: FilterViewModel[] = [];
  public loading = false;
  public ngOnInitHasBeenExecuted = false;
  public selectedFilter: FilterViewModel;
  public indexOfApplied: number;
  public bsModalRef: BsModalRef;

  private employee: Employee;
  private subscriptions = new Subscription();
  public readonly T = T;

  @HostListener('document:click', ['$event']) clickout() {
    this.isDropdownOpen = false;
  }

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    private readonly moduleService: ModuleService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly sortingService: SortingService,
    private readonly modalService: BsModalService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.employee = this.authenticationService.getCurrentEmployee();

    if (!this.label || !this.label.length) {
      this.label = this.localisationService.localiseFilterType(this.filterType);
    }

    this.initDropdownValues();

    this.indexOfApplied = this.settings.findIndex((f) => f.filterValue === this.selectedFilter.filterValue);
    this.ngOnInitHasBeenExecuted = true;
  }

  ngOnChanges() {
    if (!this.ngOnInitHasBeenExecuted) {
      return;
    }
    this.initDropdownValues();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    if (this.ngDropdownDirective) {
      this.ngDropdownDirective.onEscape();
    }
  }

  initDropdownValues() {
    const settingsByType = this.getFiltersByInputType();
    if (settingsByType?.length) {
      this.settings = JSON.parse(JSON.stringify(settingsByType)) as FilterViewModel[];
      this.loading = false;

      if (this.moduleService.currentObjectTypes?.length) {
        if (this.moduleService.currentObjectTypes.length === 1 && this.moduleService.currentObjectTypes[0] === ObjectTypes.Job) {
          this.settings.forEach((setting) => {
            if (setting.displayForGlobalObjectType === ObjectTypes.IncidentItem) {
              setting.displayForGlobalObjectType = ObjectTypes.Job;
            }
          });
          this.settings = this.settings.filter(
            (s) =>
              !s.displayForGlobalObjectType || this.moduleService.currentObjectTypes.indexOf(s.displayForGlobalObjectType) > -1
          );
        } else {
          this.settings = this.settings.filter((s) =>
            !s.displayForGlobalObjectType ||
            this.moduleService.currentObjectTypes.indexOf(s.displayForGlobalObjectType) > -1 ||
            this.moduleService.currentModule === ModuleTypes.AccountHub
          );
        }

        this.changeDetectorRef.markForCheck();
      }

      if (this.excludeFilterValues) {
        /**
         * This will remove excludeFilterValues from this.settings array (allowed dropdown values)
         * You may want to do it simple like the commented version but keep in mind that filterValues sometimes are string or number
         * and the enums that we use on the FE are with numbers
         *
         * this.settings = this.settings.filter(f => !(this.excludeFilterValues as any).includes(f.filterValue)); */
        this.settings = this.settings.filter((allowedFilter) => {
          return (
            this.excludeFilterValues.filter((filterForExclude) => {
              return filterForExclude === allowedFilter.filterValue;
            }).length === 0
          );
        });
      }

      this.settings = this.sortingService.sortFilters(this.settings, this.filterType);
      this.changeDetectorRef.detectChanges();
    }

    this.initSelectedFilter();
    this.changeDetectorRef.detectChanges();
  }

  initSelectedFilter() {
    // this.selectedFilter = this.settings.find(f => f.filterText === this.currentlySelectedFilterText);
    this.selectedFilter = this.appliedFilters.find((f) => f.filterType === this.filterType);
    this.indexOfApplied = +this.selectedFilter.filterValue;
    this.changeDetectorRef.detectChanges();
  }

  onFilterSettingSelected(fs: FilterViewModel) {
    fs.filterAction = FilterActionTypes.Update;
    if (this.isStatus && fs.filterType === FilterTypes.Incident_Status) {
      this.onIncidentStatusFilterChanged(fs);
    } else {
      this.onFiltersChanged(fs);
    }
  }

  onFiltersChanged(fs: FilterViewModel, incidentStatus: boolean = false, comment?: string) {
    this.appliedFilters = this.appliedFilters.filter((s) => s.filterType !== this.filterType);
    this.appliedFilters.push(fs);
    this.selectedFilter = fs;
    this.changeDetectorRef.detectChanges();
    this.indexOfApplied = this.settings.findIndex((f) => f.filterText === this.selectedFilter.filterText);

    if(this.useLightUpdate){
      if (incidentStatus) {
        this.onIncidentStatusFilterUpdated.emit({ filters: [fs], comment });
      } else {
        this.onFiltersUpdated.emit([fs]);
      }
    }
    else {
      if (incidentStatus) {
        this.onIncidentStatusFilterUpdated.emit({ filters: this.appliedFilters, comment });
      } else {
        this.onFiltersUpdated.emit(this.appliedFilters);
      }
    }

    if (this.ngDropdownDirective) {
      this.ngDropdownDirective.onEscape();
    }
  }

  onIncidentStatusFilterChanged(fs: FilterViewModel) {
    // Only use CloseIncidentModal if status is going from Open-Closed or vice-versa and filterType is FilterTypes.Incident_Status
    if (
      (+fs.filterValue === IncidentStatuses.Closed || fs.filterValue === IncidentStatuses.Open) &&
      fs.filterValue.toString() !==
        this.currentDetailsItem.filters.find((f) => f.filterType === FilterTypes.Incident_Status).filterValue.toString()
    ) {
      if (this.ngDropdownDirective) {
        this.ngDropdownDirective.onEscape();
      }

      const initialState = { incident: this.currentDetailsItem as IncidentItemViewModel, newFilter: fs.filterValue as IncidentStatuses };
      const modalConfig = { backdrop: true, ignoreBackdropClick: true };
      const modalParams = Object.assign({}, modalConfig, { initialState });

      const modalRef = this.modalService.show(CloseIncidentModal, modalParams);
      this.subscriptions.add(
        modalRef.content.onModalClose.subscribe((comment: string) => {
          this.onFiltersChanged(fs, true, comment);
        })
      );
    } else if (
      fs.filterValue === IncidentStatuses.Draft &&
      fs.filterValue.toString() !==
        this.currentDetailsItem.filters.find((f) => f.filterType === FilterTypes.Incident_Status).filterValue.toString()
    ) {
      this.onFiltersChanged(fs, true, '');
    }
  }

  get restOptions() {
    return this.settings.filter((f) => f.filterValue !== this.currentlySelectedFilterText);
  }

  get selectedFilterText() {
    if (this.settings && this.settings.length > 0) {
      const selectedValue = this.appliedFilters?.find((f) => f.filterType === this.filterType);
      if (selectedValue && selectedValue.filterText === '') {
        const selectedSetting = this.settings.find(
          (a) => a.filterType === selectedValue.filterType && a.filterValue == selectedValue.filterValue
        );

        if (selectedSetting) {
          selectedValue.filterText = selectedSetting.filterText;
        }
      }
      const result = selectedValue ? selectedValue.filterText : '';

      return result;
    }

    return '';
  }

  get getSelectedFilterValue() {
    const selectedValue = this.appliedFilters.find((f) => f.filterType === this.filterType);
    return selectedValue ? selectedValue.filterValue as string : '';
  }

  showDropdownOptions(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    if (this.canEdit) {
      this.isDropdownOpen = !this.isDropdownOpen;
    }
  }

  getStatusClass(filter: FilterViewModel): string {
    let statusClassName = filter?.filterText?.toLowerCase().replace(' ', '')

    if(filter?.filterType === FilterTypes.Public_Incident_Report_Status) {
      statusClassName = 'public-indicident-report-'+ statusClassName;
    }

    return filter ? statusClassName : '';
  }

  isAMilestoneFilter() {
    return this.selectedFilter?.filterType === FilterTypes.Milestone_Type;
  }

  getMilestoneIconColor() {
    if (+this.selectedFilter?.filterValue === MilestoneTypes.Bronze) return '#CD7F32';

    if (+this.selectedFilter?.filterValue === MilestoneTypes.Silver) return '#adb7be';

    if (+this.selectedFilter?.filterValue === MilestoneTypes.Gold) return '#d1ab3a';

    return 'default';
  }

  get mobile(): boolean {
    return document.body.clientWidth <= Constants.xs;
  }

  public onClick(event: MouseEvent) {
    if(this.handleClickExternally){
      this.externalClickHandle.emit(event);
    }
  }

  private getFiltersByInputType(): FilterViewModel[] {
    let filtersByType: FilterViewModel[] = [];

    const settings = this.allowedFiltersService.getCachedAllAllowedFilters();
    if (settings?.length) {
      const shouldBeLocalisedValue = FilterUtilities.IsFilterTypeWithLocalisedValues(this.filterType);

      filtersByType = settings
        .filter(s => s.filterType === this.filterType && (this.accountId === 0 || s.accountId === this.accountId))
        .map(s => {
          s.filterText = s.filterText ?? '';

          if (shouldBeLocalisedValue) {
            s.filterText = this.localisationService.localiseFilterValueByFilterType(+s.filterValue, s.filterType) as string;
          }

          return s;
        });
    }

    return filtersByType;
  }
}
