import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { ThreadedBead } from 'src/app/modules/shared/models/threadedBead';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { T } from 'src/assets/i18n/translation-keys';
import { RiskAssesmentsService } from '../../../services/risk-assesments.service';
import { RiskAssesmentDetailsViewModel } from '../../../models/riskAssesmentDetailsViewModel';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { EventsService } from 'src/app/modules/settings/services/events-service';
import { EventListItemViewModel } from 'src/app/modules/settings/viewModels/eventListItemViewModel';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { UrlService } from 'src/app/modules/shared/services/url.service';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { FilterDateOptions } from 'src/app/modules/shared/enums/filter/filterDateOptions';
import { RiskAssesmentStatuses } from 'src/app/modules/shared/enums/planning/riskAssesmentStatuses';
import { RiskAssesmentPrivacyStatuses } from 'src/app/modules/shared/enums/planning/riskAssesmentPrivacyStatuses';



type FocusFilterType = FilterTypes.Event | FilterTypes.Zone | FilterTypes.Department;
type modalType = 'add' | 'post-add';

@Component({
  selector: 'app-risk-assessment-add-modal',
  templateUrl: './risk-assessment-add-modal.component.html',
  styleUrls: ['./risk-assessment-add-modal.component.scss']
})
export class RiskAssessmentAddModalComponent implements OnInit, OnDestroy {
  @Output() assessmentAdded = new EventEmitter<RiskAssesmentDetailsViewModel>();

  public localisedRiskAssessment = this.localisationService.localiseObjectType(ObjectTypes.Risk_Assesment);
  public localisedEvent = this.localisationService.localiseObjectType(ObjectTypes.Event);
  public localisedZone = this.localisationService.localiseObjectType(ObjectTypes.Zone);
  public localisedDepartment = this.localisationService.localiseObjectType(ObjectTypes.Department);
  public currentStep: number = 0;
  public readonly T = T;
  public readonly filterTypes = FilterTypes;
  public readonly editableFieldTypes = EditableFieldTypes;
  public riskAssessment = new RiskAssesmentDetailsViewModel();
  public selectedFocusFilterType: FocusFilterType = FilterTypes.Event;
  public beads: ThreadedBead[] = [
    { number: 0, active: true },
    { number: 1, active: false },
    { number: 2, active: false },
  ];
  public allEvents: EventListItemViewModel[] = [];
  public modalType: modalType = 'add';
  public isSaving = false;
  private createdAssessmentId: number;
  private subscription = new Subscription();

  constructor(
    private readonly localisationService: LocalisationService,
    private readonly bsModalRef: BsModalRef,
    private readonly riskAssesmentsService: RiskAssesmentsService,
    private readonly eventsService: EventsService,
    private readonly urlService: UrlService
    ) { }

  ngOnInit(): void {
    this.riskAssessment.filters = [];
    this.riskAssessment.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Risk_Assesment_Privacy_Status, RiskAssesmentPrivacyStatuses.Open));
    this.riskAssessment.privacyStatus = RiskAssesmentPrivacyStatuses.Open;

    this.subscription.add(
      this.eventsService.getEventist().subscribe((events) => {
        this.allEvents = events;
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public closeModal(): void {
    this.bsModalRef.hide();
  }

  public decreaseStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
      this.updateBeads();
    }
  }

  private updateBeads(): void {
    this.beads = this.beads.slice();
    this.beads.forEach((b) => (b.active = false));

    for (let i = 0; i <= this.currentStep; i++) {
      this.beads[i].active = true;
    }
  }

  public increaseStep(): void {
    this.currentStep++;
    this.updateBeads();
  }

  public onSelectFocusType(focus: FocusFilterType): void {
    if(this.selectedFocusFilterType === focus) {
      return;
    }
    this.selectedFocusFilterType = focus;
    this.riskAssessment.filters = this.riskAssessment.filters.filter(f => f.filterType !== FilterTypes.Event && f.filterType !== FilterTypes.Zone && f.filterType !== FilterTypes.Department);
  }

  handleFocusFilterChange(filters: FilterViewModel[], type: FocusFilterType) {
    const newFocusFilter = filters.find(f => f.filterType === type);
    // Clear previously selected focus filters
    this.riskAssessment.filters = this.riskAssessment.filters.filter(f => f.filterType !== FilterTypes.Event && f.filterType !== FilterTypes.Zone && f.filterType !== FilterTypes.Department);

    // Add new filter
    this.riskAssessment.filters.push(newFocusFilter);
    // Populate title with prefix of the new focus filter
    this.riskAssessment.title = `${newFocusFilter.filterText}: Risk Assessment`;
    // Populate start date to today if not specified previously
    if(!this.riskAssessment.startDate) {
      this.riskAssessment.startDate =moment().toISOString();
    }

    // Populate due date with 2 weeks from start date
    this.riskAssessment.dueDate = moment(this.riskAssessment.startDate).add(2, 'weeks').toISOString();

    if(type === FilterTypes.Event) {
      // Populate due date with start date of the selected event
      const mathcingEvent = this.allEvents.find(e => e.id === newFocusFilter.filterValue);
      const isEventStartingAfterAssessmentStartDate = moment(mathcingEvent.startDate).isAfter(moment());
      if(isEventStartingAfterAssessmentStartDate) {
        this.riskAssessment.dueDate = mathcingEvent.startDate;
      }
    }
  }

  get isValid(): boolean {
    if (this.currentStep === 0) {
      return this.riskAssessment.filters.some((f) => f.filterType === this.selectedFocusFilterType);
    } else if (this.currentStep === 1) {
      const hasTitle = !!this.riskAssessment.title && this.riskAssessment.title.length > 3;
      const hasStartDate = !!this.riskAssessment.startDate;
      const hasDueDate = !!this.riskAssessment.dueDate;
      return hasTitle && hasStartDate && hasDueDate;
    }else if (this.currentStep === 2) {
      const hasOwner = this.riskAssessment.filters.some(f => f.filterType === FilterTypes.Owner);
      const hasApprover = this.riskAssessment.filters.some(f => f.filterType === FilterTypes.Approver);
      return hasOwner && hasApprover;
    }

    return false;
  }

  public onSave(): void {
    this.riskAssessment.approverId = +this.riskAssessment.filters.find(f => f.filterType === FilterTypes.Approver)?.filterValue;
    this.riskAssessment.ownerId = +this.riskAssessment.filters.find(f => f.filterType === FilterTypes.Owner)?.filterValue;
    this.riskAssessment.eventId = +this.riskAssessment.filters.find(f => f.filterType === FilterTypes.Event)?.filterValue;
    this.riskAssessment.status = RiskAssesmentStatuses.Draft;
    const draftStatusFilter = FilterUtilities.GenerateFilter(FilterTypes.Risk_Assesment_Status, RiskAssesmentStatuses.Draft);
    this.riskAssessment.privacyStatus = +this.riskAssessment.filters.find(f => f.filterType === FilterTypes.Risk_Assesment_Privacy_Status)?.filterValue;
    this.riskAssessment.filters.push(draftStatusFilter);
    this.isSaving = true;
    this.riskAssesmentsService.add(this.riskAssessment).subscribe((res) => {
      if(res.returnModel) {
        this.createdAssessmentId = +res.returnModel.id;
        this.changeModalType('post-add');
      }
      this.isSaving = false;
    })
  }

  public onDescriptionChange(description: string): void {
    this.riskAssessment.description = description;
  }

  public onStartDateChanged(date: string): void {
    this.riskAssessment.startDate = date;
    this.riskAssessment.filters = this.riskAssessment.filters.filter(f => f.filterType !== FilterTypes.Date && f.dateProperty !== FilterDateOptions.Start_Date);
    const startDateFilter = FilterUtilities.GenerateFilter(FilterTypes.Date, date, '', FilterDateOptions.Start_Date)
    this.riskAssessment.filters.push(startDateFilter);
  }

  public onDueDateChanged(date: string): void {
    this.riskAssessment.dueDate = date;
    this.riskAssessment.filters = this.riskAssessment.filters.filter(f => f.filterType !== FilterTypes.Date && f.dateProperty !== FilterDateOptions.Due_Date);
    const startDateFilter = FilterUtilities.GenerateFilter(FilterTypes.Date, date, '', FilterDateOptions.Due_Date)
    this.riskAssessment.filters.push(startDateFilter);
  }

  public handleFilterChange(filters: FilterViewModel[]): void {
    this.riskAssessment.filters = filters.slice();
  }

  changeModalType(type: modalType): void {
    this.modalType = type;
    if(this.modalType === 'post-add') {
      this.bsModalRef.setClass('');
    } else {
      this.bsModalRef.setClass('modal-full-screen');
    }
  }

  public navigateToRiskAssessment(): void {
    this.urlService.navigateToObjectDetailsPage(ObjectTypes.Risk_Assesment, null, this.createdAssessmentId, false);
    this.closeModal();
  }

  public addAnotherAssessment(): void {
    this.riskAssessment = new RiskAssesmentDetailsViewModel();
    this.riskAssessment.filters = [];
    this.riskAssessment.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Risk_Assesment_Privacy_Status, RiskAssesmentPrivacyStatuses.Open));
    this.riskAssessment.privacyStatus = RiskAssesmentPrivacyStatuses.Open;
    this.currentStep = 0;
    this.updateBeads();
    this.changeModalType('add');
  }
}
