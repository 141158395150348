import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterTypes } from '../../../enums/filterTypes';
import { SortingService } from '../../../services/sorting.service';
import { Subscription } from 'rxjs';
import { ObjectTypes } from '../../../enums/objectTypes';
import { FilterTypeVisibility } from '../../../enums/filter/filterTypeVisibility';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { EventDetailsViewModel } from 'src/app/modules/settings/viewModels/eventDetailsViewModel';
import { Employee } from '../../../models/employee';
import { AuthenticationService } from '../../../services/authentication.service';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import { EmployeeCardFilterSetting } from '../../../models/employeeCardFilterSetting';
import { FilterTypeLozengeLabelUtillities } from '../../../utilities/filterType-Lozenge-Label.utilities';
import { MilestoneTypes } from '../../../enums/milestoneTypes';
import { EmployeeCardShowingFilter } from '../../../models/employeeCardShowingFilter';

@Component({
  selector: 'app-card-lozenges-list',
  templateUrl: './card-lozenges-list.component.html',
  styleUrls: ['./card-lozenges-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'custom-scrollbar' },
})
export class CardLozengesListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() objectType: ObjectTypes;
  @Input() filters: FilterViewModel[];
  @Input() visibleFilterTypeSelector: FilterTypeSelectorViewModel[];
  @Input() employee: Employee;
  @Input() canEdit: boolean = false;
  @Input() comparatorDate: string;

  @Output() taskRAGBreakdownClicked: EventEmitter<FilterViewModel> = new EventEmitter();
  @Output() riskRAGBreakdownClicked: EventEmitter<FilterViewModel> = new EventEmitter();

  @Output() lozengeUpdate: EventEmitter<FilterViewModel[]> = new EventEmitter();
  @Output() lozengeClicked: EventEmitter<FilterTypes> = new EventEmitter();
  @Output() lozengeHover: EventEmitter<FilterTypes> = new EventEmitter();

  public events: EventDetailsViewModel[] = [];
  public visibleLozenges: FilterTypeSelectorViewModel[] = [];

  private subscriptions = new Subscription();
  private employeeCardFilterSettings: EmployeeCardFilterSetting;

  constructor(
    private readonly changedetectorRef: ChangeDetectorRef,
    private readonly authenticationService: AuthenticationService,
    private readonly sortingService: SortingService
  ) {}

  ngOnInit(): void {
    if (!this.employee) {
      this.employee = this.authenticationService.getCurrentEmployee();
    }

    this.setvisibleFilters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visibleFilterTypeSelector && changes.visibleFilterTypeSelector.previousValue && changes.visibleFilterTypeSelector.currentValue) {
      this.setvisibleFilters();
    }
  }

  setvisibleFilters() {
    this.visibleLozenges = [];
    if (this.visibleFilterTypeSelector) {
      this.employeeCardFilterSettings = this.sortingService.getEmployeeCardFilterSetting(this.objectType);

      this.visibleFilterTypeSelector = this.visibleFilterTypeSelector
        ? this.visibleFilterTypeSelector.sort((a, b) => (a.viewOrder > b.viewOrder ? 1 : -1))
        : this.visibleFilterTypeSelector;
      this.filters = this.filters?.sort((a, b) => (a.viewOrder > b.viewOrder ? 1 : -1));

      if (this.objectType !== ObjectTypes.PEAP_Checkpoint) {
        this.visibleFilterTypeSelector.forEach((vis) => {
          const ignoredLozenges = FilterTypeLozengeLabelUtillities.ignoredLozenges(vis.displayForObjectType);

          if (this.employeeCardFilterSettings && this.employeeCardFilterSettings.showingFilters) {
            if (
              this.employeeCardFilterSettings.showingFilters.findIndex(
                (a) => a.filterType == vis.filterType && a.filterDateOption == vis.dateProperty
              ) > -1
            ) {
              if (ignoredLozenges.findIndex((a) => a == vis.filterType) < 0) {
                this.visibleLozenges.push(vis);
              }
            }
          }
        });

        if (
          this.objectType == ObjectTypes.Task &&
          this.filters.some((f) => f.filterType == FilterTypes.Milestone_Type && f.filterValue == MilestoneTypes.No_Milestone)
        ) {
          this.visibleLozenges = this.visibleLozenges.filter((loz) => loz.filterType != FilterTypes.Milestone_Type);
        }
        if (
          this.objectType == ObjectTypes.Task &&
          this.filters.some((f) => f.filterType == FilterTypes.Milestone_Type && f.filterValue != MilestoneTypes.No_Milestone)
        ) {
          this.visibleLozenges = this.visibleLozenges.filter(
            (loz) => !(loz.filterType == FilterTypes.Date && loz.dateProperty == FilterDateOptions.Start_Date)
          );
        }
      } else {
        this.visibleLozenges = this.visibleFilterTypeSelector.slice();

        if (this.objectType == ObjectTypes.PEAP_Checkpoint) {
          this.employeeCardFilterSettings = new EmployeeCardFilterSetting();
          this.employeeCardFilterSettings.showEmptyLozenges = false;
          this.employeeCardFilterSettings.showingFilters = [];
          this.visibleLozenges.forEach((lozenge) => {
            const setting = new EmployeeCardShowingFilter();
            setting.filterType = lozenge.filterType;
            setting.filterDateOption = 0 as FilterDateOptions;
            this.employeeCardFilterSettings.showingFilters.push(setting);
          });
        }
      }
    }

    this.changedetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Get selected FilterViewModel in order to populate the lozenge
   * @param selectedFilterType  already selected lozenge for showing.
   */
  public GetVisibleFiltersByFilterType(selectedFilterType: FilterTypeSelectorViewModel): FilterViewModel[] {
    let result: FilterViewModel[] = [];

    if (this.employeeCardFilterSettings && this.employeeCardFilterSettings.showingFilters) {
      if (
        this.employeeCardFilterSettings.showingFilters.findIndex(
          (a) => a.filterType == selectedFilterType.filterType && a.filterDateOption == selectedFilterType.dateProperty
        ) > -1
      ) {
        const ftype = selectedFilterType.filterType;
        const dateProperty = selectedFilterType.dateProperty;

        const data = this.filtersByFilterType(ftype, dateProperty);

        if (data && data.length > 0) {
          result = data;
        } else {
          const emptyFilterVm = new FilterViewModel();
          emptyFilterVm.filterType = ftype;
          emptyFilterVm.filterDropdownTitle = selectedFilterType.filterTypeText;
          emptyFilterVm.dateProperty = dateProperty;
          emptyFilterVm.filterValue = -1;
          result = [emptyFilterVm];
        }
      }
    }

    const distArr = [];

    result.forEach((item) => {
      if (distArr.findIndex((s) => s.id == item.id) < 0) {
        distArr.push(item);
      }
    });

    return distArr;
  }

  /**
   * filter to list of visible only filters by validating it against few rules
   *
   * @param filterType specific filter type
   */
  filtersByFilterType(filterType: FilterTypes, dateoptions: FilterDateOptions): FilterViewModel[] {
    const applicableFilters = this.filters.filter(
      (a) =>
        a.dateProperty == dateoptions &&
        a.filterType == filterType &&
        (a.visibility == FilterTypeVisibility.Both || a.visibility == FilterTypeVisibility.Card_Modal_Only)
    );

    if (applicableFilters && applicableFilters.length > 0) {
      return applicableFilters;
    }

    return [];
  }

  getFilterTypeSelectorViewModelByFilterType(filterType: FilterTypes): FilterTypeSelectorViewModel {
    return this.visibleFilterTypeSelector.find(
      (filterTypeSelectorViewModel) => filterTypeSelectorViewModel.filterType === filterType
    );
  }

  onTaskRAGBreakdownClicked(f: FilterViewModel) {
    this.taskRAGBreakdownClicked.emit(f);
  }
  onRiskRAGBreakdownClicked(f: FilterViewModel) {
    this.riskRAGBreakdownClicked.emit(f);
  }

  onLozengeUpdated(filters: FilterViewModel[], filterType: FilterTypes) {
    let removedFilters = this.filters.filter((f) => f.filterType == filterType && filters.findIndex((s) => s.id == f.id) < 0);
    this.filters = this.filters.filter((f) => removedFilters.findIndex((s) => s.id == f.id) < 0);

    if (filters.length > 0 && filters[0].filterType == FilterTypes.Date) {
      this.filters = this.filters.filter(
        (d) => !filters.some((s) => s.filterType == d.filterType && s.dateProperty == d.dateProperty)
      );
    } else {
      this.filters = this.filters.filter((d) => filters.findIndex((s) => s.filterType == d.filterType) < 0);
    }
    this.filters = this.filters.concat(filters);

    this.lozengeUpdate.next(this.filters);
  }

  handleLozengeClick(filterType: FilterTypes) {
    this.lozengeClicked.next(filterType);
  }

  handleLozengeHover(filterType: FilterTypes) {
    this.lozengeHover.next(filterType);
  }

  trackBy(index, item: FilterTypeSelectorViewModel) {
    return item.filterType;
  }

  showLozenge(type: FilterTypes, dateProperty: FilterDateOptions) {
    let result = true;

    if (this.employeeCardFilterSettings && !this.employeeCardFilterSettings.showEmptyLozenges) {
      let matchingFilters: FilterViewModel[] = [];
      if (type == FilterTypes.Date) {
        matchingFilters = this.filters?.filter((f) => f.filterType == type && f.dateProperty == dateProperty && f.filterValue);
      } else {
        matchingFilters = this.filters?.filter((f) => f.filterType == type && f.filterValue && +f.filterValue > 0);
      }

      if (!matchingFilters || !matchingFilters.length) {
        result = false;
      }
    }

    return result;
  }
}
